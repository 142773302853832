import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { readableCurrency } from '../../../common/utils';
import CustomTable, { TableColumn } from '../../../table/CustomTable';
import PersonnelAddModal from './PersonnelAddModal';
import PersonnelDeleteModal from './PersonnelDeleteModal';
import PersonnelUpdateModal from './PersonnelUpdateModal';

const columns: TableColumn<WorkorderAssignmentWithRate>[] = [
    {
        id: 'full_name',
        title: 'Name',
        render_strategy: (war: WorkorderAssignmentWithRate) => <p>{ war.full_name }</p>
    },
    {
        id: 'email',
        title: 'Email',
        render_strategy: (war: WorkorderAssignmentWithRate) => <p>{ war.email }</p>
    },
    {
        id: 'ad_username',
        title: 'AD',
        render_strategy: (war: WorkorderAssignmentWithRate) => <p>{ war.ad_username }</p>
    },
    {
        id: 'bill_rate',
        title: 'Bill Rate',
        render_strategy: (war: WorkorderAssignmentWithRate) => <p>{ readableCurrency(war.bill_rate) }</p>
    },
    {
        id: 'cost_rate',
        title: 'Cost Rate',
        render_strategy: (war: WorkorderAssignmentWithRate) => <p>{ readableCurrency(war.cost_rate) }</p>
    }
]

interface PersonnelTableAdminProps {
    loading: boolean;
    data: WorkorderAssignmentWithRate[];
    refresh: () => void;
    workorder_id: string;
}

const PersonnelTableAdmin = (props: PersonnelTableAdminProps): JSX.Element => {
    const { isOpen: addIsOpen, onOpen: addOnOpen, onClose: addOnClose } = useDisclosure();
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState({
        id: -1,
        full_name: '',
        email: '',
        ad_username: '',
        workorder_id: -1,
        personnel_id: -1,
        rate_id: -1,
        cost_rate: Number.NaN,
        bill_rate: Number.NaN
    } as WorkorderAssignmentWithRate);
    
    const onDelete = (data: WorkorderAssignmentWithRate) => {
        setSelectedRow(data);
        deleteOnOpen();
    }

    const onEdit = (data: WorkorderAssignmentWithRate) => {
        setSelectedRow(data);
        updateOnOpen();
    }

    return (
        <>
            <CustomTable
                tableHeader='Personnel'
                columns={columns}
                loading={props.loading}
                data={props.data}
                onDelete={onDelete}
                onEdit={onEdit}
                onAdd={addOnOpen}
            />
            <PersonnelAddModal 
                refreshTable={props.refresh}
                isOpen={addIsOpen}
                closeModal={addOnClose}
                workorder_id={props.workorder_id}
                already_added_wars={props.data}
            />
            <PersonnelDeleteModal
                refreshTable={props.refresh}
                isOpen={deleteIsOpen}
                closeModal={deleteOnClose}
                data={selectedRow}
            />
            <PersonnelUpdateModal
                refreshTable={props.refresh}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                data={selectedRow}
            />
        </>
    );  
}

export interface WorkorderAssignmentWithRate {
    id: number; // workorder assignment id
    full_name: string;
    email: string;
    ad_username: string;
    workorder_id: number;
    personnel_id: number;
    rate_id: number;
    cost_rate: number;
    bill_rate: number;
};

export interface Personnel {
    id: number;
    full_name: string;
    email: string;
    ad_username: string;
}

export default PersonnelTableAdmin;