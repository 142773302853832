import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../../App';
import FormModal from '../../../modal/FormModal';
import { MenuAttributeProps, MenuAttributeTypes } from '../../../modal/MenuAttribute';
import { WorkorderAssignmentWithRate } from './PersonnelTableAdmin';

const queryClient = new QueryClient();

interface PersonnelUpdateModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: WorkorderAssignmentWithRate;
}

const PersonnelUpdateModal = (props: PersonnelUpdateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <PersonnelUpdateModalInner {...props}/>
    </QueryClientProvider>
)

const PersonnelUpdateModalInner = (props: PersonnelUpdateModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [cost_rate, setCostRate] = React.useState(Number.NaN);
    const [bill_rate, setBillRate] = React.useState(Number.NaN);
    useEffect(() => {
        setCostRate(props.data.cost_rate);
        setBillRate(props.data.bill_rate);
    }, [props.data]);

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Cost Rate",
            attributeType: MenuAttributeTypes.currency_input,
            set_value: cost_rate,
            setValue: setCostRate
        },
        {
            name: "Bill Rate",
            attributeType: MenuAttributeTypes.currency_input,
            set_value: bill_rate,
            setValue: setBillRate
        }
    ];

    // disabled if there exists a form element w/ length 0
    const isSaveButtonDisabled = (): boolean =>
        Number.isNaN(cost_rate) || Number.isNaN(bill_rate);
    
    // submit request stuff
    const { data, status, refetch, remove } = useQuery('rate_update', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/rate`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.rate_id,
                    cost_rate: cost_rate,
                    bill_rate: bill_rate,
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            setBillRate(Number.NaN);
            setCostRate(Number.NaN);
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Update Rate'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default PersonnelUpdateModal;