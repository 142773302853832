import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import FormModal from '../../modal/FormModal';
import { Client } from './ClientTable';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';

const queryClient = new QueryClient()

interface ClientUpdateModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: Client;
}

const ClientUpdateModal = (props: ClientUpdateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <ClientUpdateModalInner {...props}/>
    </QueryClientProvider>
)

const ClientUpdateModalInner = (props: ClientUpdateModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [nameValue, setNameValue] = React.useState(props.data.name);
    useEffect(() => {
        setNameValue(props.data.name);
    }, [props.data]);


    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Name",
            attributeType: MenuAttributeTypes.short_input,
            set_value: nameValue,
            setValue: setNameValue
        }
    ];

    // disabled if there exists a form element w/ length 0
    const isSaveButtonDisabled = (): boolean =>
        [nameValue].find((el: string) => el.length === 0) !== undefined;
    
    // submit request stuff
    const { data, status, refetch, remove } = useQuery('client_update', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/client`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {id: props.data.id, name: nameValue}
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Update Client'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default ClientUpdateModal