import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import FormModal from '../../modal/FormModal';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';

const queryClient = new QueryClient()

interface TimesheetLockModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const TimesheetLockModal = (props: TimesheetLockModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimesheetLockModalInner {...props}/>
    </QueryClientProvider>
);

const TimesheetLockModalInner = (props: TimesheetLockModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [dateValue, setDateValue] = React.useState(new Date());
    
    const { refetch, status, data, remove } = useQuery('lock_timesheets', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/lock_timesheets`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    date: dateValue
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: 'Lock All Time Entries On And Before Date',
            attributeType: MenuAttributeTypes.date_input,
            set_value: dateValue,
            setValue: setDateValue,
        }
    ];
    const isSaveButtonDisabled = (): boolean => false;
    
    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            setDateValue(new Date());
            remove();
            props.closeModal();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Lock Timesheets'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default TimesheetLockModal;