import { Button } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { readableDate } from '../../common/utils';
import DatePickerInput from '../../modal/DatePickerInput';
import AnalysisDisplay from './AnalysisDisplay';
import "./AnalysisPage.css";

const queryClient = new QueryClient();

interface AnalysisPageProps {}

const AnalysisPage = (props: AnalysisPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <AnalysisPageInner {...props}/>
    </QueryClientProvider>
);

const AnalysisPageInner = (props: AnalysisPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [startDate, setStartDate] = React.useState(new Date().toString());
    const [endDate, setEndDate] = React.useState(new Date().toString());

    const { data: lockDateData, status: lockDateStatus } = useQuery('lock_date', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/lock_date?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
    });
    const lockDateloading = lockDateStatus !== 'success' || lockDateData.STATUS !== 'SUCCESS';
    const lockDateStr = lockDateloading ? '' : readableDate(new Date(lockDateData.DATA));

    const { data, status, refetch } = useQuery('analysis', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/analysis_fetch?access_token=${sessionDetails.access_token}&startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false, // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    useEffect(() => {
        refetch();
    }, [startDate, endDate, refetch]);

    return (
        <div className='pageWrapper'>
            Lock date: {lockDateStr}
            <div className='selectorsWrapper'>
                <div className='selectorWrapper'>
                    <h2>Start Date</h2>
                    <DatePickerInput
                        set_value={startDate}
                        setValue={setStartDate}
                    />
                </div>
                <div className='selectorWrapper'>
                    <h2>End Date</h2>
                    <DatePickerInput
                        set_value={endDate}
                        setValue={setEndDate}
                    />
                </div>
            </div>
            <div className='analysisWrapper'>
                analysis
                { 
                    !loading
                        ? <AnalysisDisplay data={loading ? [] : data.DATA}/>
                        : <p>loading...</p>
                }
            </div>

        </div>
    );
}

export default AnalysisPage;