import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import CustomTable, { TableColumn } from '../../table/CustomTable';
import ClientContactAddModal from './ClientContactAddModal';
import ClientContactUpdateModal from './ClientContactUpdateModal';
import ClientContactDeleteModal from './ClientContactDeleteModal';
  
const columns: TableColumn<ClientContact>[] = [
    {
        id: 'name',
        title: 'Name',
        render_strategy: (clientContact: ClientContact) => <p>{ clientContact.name }</p>
    },
    {
        id: 'phone',
        title: 'Phone',
        render_strategy: (clientContact: ClientContact) => <p>{ clientContact.phone }</p>
    },
    {
        id: 'address',
        title: 'Address',
        render_strategy: (clientContact: ClientContact) => <p>{ clientContact.address }</p>
    },
    {
        id: 'email',
        title: 'Email',
        render_strategy: (clientContact: ClientContact) => <p>{ clientContact.email }</p>
    },
];

interface ClientContactTableProps {
    loading: boolean;
    data: ClientContact[];
    refresh: () => void;
    client_name: string;
    client_id: string;
}

const ClientContactTable = (props: ClientContactTableProps): JSX.Element => {
    const { isOpen: addIsOpen, onOpen: addOnOpen, onClose: addOnClose } = useDisclosure();
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState({
        id: -1,
        client_id: -1,
        name: '',
        phone: '',
        address: '',
        email: ''
    } as ClientContact);

    const onEdit = (data: ClientContact) => {
        setSelectedRow(data);
        updateOnOpen();
    }

    const onDelete = (data: ClientContact) => {
        setSelectedRow(data);
        deleteOnOpen();
    }

    return (
        <>
            <CustomTable
                tableHeader={`Contacts for ${props.client_name}`}
                columns={columns}
                loading={props.loading}
                data={props.data}
                onDelete={onDelete}
                onEdit={onEdit}
                onAdd={addOnOpen}
            />
            <ClientContactAddModal
                refreshTable={props.refresh}
                isOpen={addIsOpen}
                closeModal={addOnClose}
                client_id={props.client_id}
            />
            <ClientContactUpdateModal
                refreshTable={props.refresh}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                data={selectedRow}
            />
            <ClientContactDeleteModal
                refreshTable={props.refresh}
                isOpen={deleteIsOpen}
                closeModal={deleteOnClose}
                data={selectedRow}
            />
        </>
    );
}

export interface ClientContact {
    id: number;
    client_id: number;
    name: string;
    phone: string;
    address: string;
    email: string;
}

export default ClientContactTable;