import { useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { InternalLink } from '../../common/components';
import { get_workorder_counties_list_readable, readableCurrency, readableDate } from '../../common/utils';
import { DropdownItem } from '../../modal/MenuAttribute';
import CustomTable, { TableColumn } from '../../table/CustomTable';
import { County } from '../county/CountyTable';
import WorkorderAddModal from './WorkorderAddModal';
import WorkorderDeleteModal from './WorkorderDeleteModal';
import WorkorderUpdateModal from './WorkorderUpdateModal';

export enum JobType {
    ABSTRACT = 'abstract',
    DUE_DILIGENCE = 'due_diligence',
    MAPPING = 'mapping',
    OIL_GAS_TITLE_AND_LEASING = 'oil_gas_title_and_leasing',
    MINING_TITLE = 'mining_title',
    ROW_EASEMENT = 'row_easement',
    DATA_MANAGEMENT = 'data_management',
    IMAGING = 'imaging',
    OTHER = 'other'
}
export const JobTypeDropdownItems: DropdownItem[] = [
    {
        id: JobType.ABSTRACT,
        display: 'Abstract'
    }, {
        id: JobType.DUE_DILIGENCE,
        display: 'Due Diligence'
    }, {
        id: JobType.MAPPING,
        display: 'Mapping'
    }, {
        id: JobType.OIL_GAS_TITLE_AND_LEASING,
        display: 'Oil Gas Title and Leasing'
    }, {
        id: JobType.MINING_TITLE,
        display: 'Mining Title'
    }, {
        id: JobType.ROW_EASEMENT,
        display: 'Row Easement'
    }, {
        id: JobType.DATA_MANAGEMENT,
        display: 'Data Management'
    }, {
        id: JobType.IMAGING,
        display: 'Imaging'
    }, {
        id: JobType.OTHER,
        display: 'Other'
    }
];
export const getReadableJobType = (jobType: string) => JobTypeDropdownItems.find((item: DropdownItem) => item.id === jobType)?.display || jobType;

export enum WorkorderStatus {
    PENDING = 'pending', 
    IN_PROGRESS = 'in_progress',
    HOLD = 'hold',
    COMPLETE = 'complete'
}
export const WorkorderStatusDropdownItems: DropdownItem[] = [
    {
        id: WorkorderStatus.PENDING,
        display: 'Pending'
    }, {
        id: WorkorderStatus.IN_PROGRESS,
        display: 'In Progress'
    }, {
        id: WorkorderStatus.HOLD,
        display: 'Hold'
    }, {
        id: WorkorderStatus.COMPLETE,
        display: 'Complete'
    }, 
];
export const getReadableWorkorderStatus= (woStatus: string) => WorkorderStatusDropdownItems.find((item: DropdownItem) => item.id === woStatus)?.display || woStatus;

const columns = (workorder_route: string, admin_display: boolean): TableColumn<Workorder>[] => {
    return [
        {
            id: 'name',
            title: 'Name',
            render_strategy: (wo: Workorder) =>  <p><InternalLink content={wo.workorder_name} route={`/${workorder_route}/${wo.id}`}/></p>
        },
        {
            id: 'status',
            title: 'Status',
            render_strategy: (wo: Workorder) => <p>{ getReadableWorkorderStatus(wo.workorder_status) }</p>
        },
        {
            id: 'client',
            title: 'Client',
            render_strategy: (wo: Workorder) => <p>{ wo.client_name }</p>
        },
        admin_display ? {
            id: 'contact',
            title: 'Client Contact',
            render_strategy: (wo: Workorder) => <p>{ wo.client_contact_name }</p>
        } : null,
        {
            id: 'county',
            title: 'County',
            render_strategy: (wo: Workorder) => <p>{ get_workorder_counties_list_readable(wo) }</p>
        },
        {
            id: 'job_type',
            title: 'Job Type',
            render_strategy: (wo: Workorder) => <p>{ getReadableJobType(wo.job_type) }</p>
        },
        {
            id: 'date_received',
            title: 'Date Received',
            render_strategy: (wo: Workorder) => <p>{ readableDate(new Date(wo.date_received)) }</p>
        },
        {
            id: 'date_due',
            title: 'Date Due',
            render_strategy: (wo: Workorder) => <p>{ readableDate(new Date(wo.date_due)) }</p>
        },
        admin_display ? {
            id: 'estimate_amount',
            title: 'Estimate',
            render_strategy: (wo: Workorder) => <p>{ wo.estimate_amount ? readableCurrency(wo.estimate_amount) : ""}</p>
        } : null
    ].filter((col: TableColumn<Workorder> | null) => col !== null) as TableColumn<Workorder>[];
};

interface WorkorderTableProps {
    loading: boolean;
    data: Workorder[];
    workorder_route: string;
    admin_display: boolean;
    refresh: () => void;
    onLoadMoreButtonPress?: () => void;
}

const WorkorderTable = (props: WorkorderTableProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const { isOpen: addIsOpen, onOpen: addOnOpen, onClose: addOnClose } = useDisclosure();
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState({
        id: -1,
        workorder_name: '',
        workorder_status: WorkorderStatus.PENDING,
        client_id: -1,
        client_name: '',
        client_contact_id: -1,
        client_contact_name: '',
        counties: [],
        job_type: JobType.OTHER,
        date_received: new Date(''),
        date_due: new Date(''),
        archived: false
    } as Workorder);
    const [ needsToSendArchiveRequest, setNeedsToSendArchiveRequest ] = React.useState(false);
    
    // LOCK/UNLOCK
    const { refetch: sendArchiveRequest } = useQuery('workorder_archive', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder_archive`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    workorder_id: selectedRow.id,
                    archived: !selectedRow.archived
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
        onSuccess: (data) => {
            props.refresh();
        }
    });

    useEffect(() => {
        if (needsToSendArchiveRequest) {
            setNeedsToSendArchiveRequest(false);
            sendArchiveRequest();
        }
    }, [needsToSendArchiveRequest, sendArchiveRequest]);

    if (!props.admin_display) {
        return (
            <CustomTable
                tableHeader={'Workorders'}
                columns={columns(props.workorder_route, props.admin_display)}
                loading={props.loading}
                data={props.data}
            />
        );
    }

    const onEdit = (data: Workorder) => {
        setSelectedRow(data);
        updateOnOpen();
    }

    const onDelete = (data: Workorder) => {
        setSelectedRow(data);
        deleteOnOpen();
    }

    const onArchive = (data: Workorder) => {
        setSelectedRow(data);
        setNeedsToSendArchiveRequest(true);
    };
    const isRowArchived = (data: Workorder) => data.archived;

    return (
        <>
            <CustomTable
                tableHeader={'Workorders'}
                columns={columns(props.workorder_route, props.admin_display)}
                loading={props.loading}
                data={props.data}
                onDelete={onDelete}
                onEdit={onEdit}
                onAdd={addOnOpen}
                onArchive={onArchive}
                isRowArchived={isRowArchived}
                disableRow={isRowArchived}
                onLoadMore={props.onLoadMoreButtonPress}
            />
            {addIsOpen && <WorkorderAddModal
                refreshTable={props.refresh}
                isOpen={addIsOpen}
                closeModal={addOnClose}
            />}
            {updateIsOpen && <WorkorderUpdateModal
                refreshTable={props.refresh}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                data={selectedRow}
            />}
            {deleteIsOpen && <WorkorderDeleteModal
                refreshTable={props.refresh}
                isOpen={deleteIsOpen}
                closeModal={deleteOnClose}
                data={selectedRow}
            />}
        </>
    );
}

export interface Workorder {
    id: number;
    workorder_name: string;
    workorder_status: WorkorderStatus;
    client_id: number;
    client_name: string;
    client_contact_id: number;
    client_contact_name: string;
    counties: County[];
    job_type: JobType;
    date_received: Date;
    date_due: Date;
    archived: boolean;
    estimate_amount?: number;
}

export default WorkorderTable;

