import { KeyboardReturnOutlined } from '@material-ui/icons';
import JSZip from 'jszip';
import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { readableDate } from '../../common/utils';
import FormModal from '../../modal/FormModal';
import { DropdownItem, MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import { Personnel } from '../personnel/admin/PersonnelTableAdmin';
import { Workorder } from '../workorders/WorkorderTable';
import { TaskTypeDropdownItems, TimeEntry, TimeEntryTableLocation } from './TimeEntryTable';

const queryClient = new QueryClient();

interface TimeEntryAddModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    table_location: TimeEntryTableLocation;
    workorder?: Workorder;
    user_permissions: UserPermissions;
    duplicate_data: TimeEntry;
    useLockDate: boolean;
}

const TimeEntryAddModal = (props: TimeEntryAddModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimeEntryFormModal {...props} />
    </QueryClientProvider>
);

const TimeEntryFormModal = (props: TimeEntryAddModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const [personnel, setPersonnel] = React.useState({id: '', display: ''} as DropdownItem);
    const [personnelOptions, setPersonnelOptions] = React.useState([] as DropdownItem[]);
    const [workorder, setWorkorder] = React.useState({id: '', display: ''} as DropdownItem);
    const [workorderOptions, setWorkorderOptions] = React.useState([] as DropdownItem[]);
    const [task_type, setTaskType] = React.useState({id: '', display: ''} as DropdownItem);
    const [date, setDate] = React.useState(new Date());
    const [work_description, setWorkDescription] = React.useState('');
    const [lodging, setLodging] = React.useState(0);
    const [meals, setMeals] = React.useState(0);
    const [mileage, setMileage] = React.useState(0);
    const [copies_documents, setCopiesDocuments] = React.useState(0);
    const [other_expenses, setOtherExpenses] = React.useState(0);
    const [other_expenses_detail, setOtherExpensesDetail] = React.useState('');
    const [day_worked, setDayWorked] = React.useState({id: '', display: ''} as DropdownItem);
    const [files, setFilesIncomplete] = React.useState<FileList[]>([]);
    const [ticker, setTicker] = React.useState(0);

    const [message, setMessage] = React.useState('');
    const [validDate, setValidDate] = React.useState(true);

    const setFiles = (a: any) => {
        setTicker(ticker + 1);
        setFilesIncomplete(a);
    }

    // DATE LOCK FETCHING
    const { data: lockDateData, status: lockDateStatus } = useQuery('date_lock_fetch', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/lock_date?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
    });
    const loading = lockDateStatus !== 'success' || lockDateData.STATUS !== 'SUCCESS';
    const lockDate = loading ? new Date() : new Date(lockDateData.DATA);
    const lockDateStr = loading ? '' : readableDate(lockDate);

    // WORKORDER FETCHING
    const { 
        data: get_workorder_data,
        status: get_workorder_status, 
    } = useQuery('get_workorders', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/${props.table_location === TimeEntryTableLocation.AllTimesheets ? 'workorders' : 'workorder'}?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const options = data.DATA.map((workorder: Workorder) => ({
                id: workorder.id + '',
                display: workorder.workorder_name
            } as DropdownItem));
            setWorkorderOptions(options);
            setWorkorder(
                props.workorder
                ? options.find((option: DropdownItem) => option.id === (props.workorder && props.workorder.id + '') || '')|| {id: '', display: ''}
                : {id: '', display: ''}
            );
        }
    });
    const get_workorder_loading = get_workorder_status !== 'success' || get_workorder_data.STATUS !== 'SUCCESS';
    

    // PERSONNEL FETCHING
    const { 
        data: get_personnel_data,
        status: get_personnel_status, 
        refetch: get_personnel_refetch
    } = useQuery('get_personnel_workorder', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/personnel_workorder?access_token=${sessionDetails.access_token}&workorder_id=${workorder.id}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (data) => {
            const options: DropdownItem[] = data.DATA.personnel.map((employee: Personnel) => ({
                id: employee.id + '',
                display: employee.full_name
            } as DropdownItem));
            setPersonnelOptions(options);
            setPersonnel(options.find((option: DropdownItem) => option.id === (data.DATA.requester_id + '')) || {id: '', display: ''});
        }
    });
    const get_personnel_loading = get_personnel_status !== 'success' || get_personnel_data.STATUS !== 'SUCCESS';


    useEffect(() => {
        if(workorder.id.length > 0) {
            get_personnel_refetch();
        }
    }, [workorder, get_personnel_refetch]);

    useEffect(() => {
        if(props.duplicate_data.id !== -1 && workorderOptions.length > 0) {
            setWorkorder(workorderOptions.find((option: DropdownItem) => option.id === (props.duplicate_data.workorder_id + '') || '')|| {id: '', display: ''});
        }
    }, [workorderOptions]);

    useEffect(() => {
        if(props.duplicate_data.id !== -1 && personnelOptions.length > 0) {
            setPersonnel(personnelOptions.find((option: DropdownItem) => option.id === (props.duplicate_data.personnel_id + '')) || {id: '', display: ''});
        }
    }, [personnelOptions]);

    useEffect(() => {
        if( props.duplicate_data.id !== -1 && personnel.id.length > 0 && workorderOptions.length > 0) {
            setWorkorder(workorderOptions.find((option: DropdownItem) => option.id === (props.duplicate_data.workorder_id + '') || '')|| {id: '', display: ''});
            const date = new Date(props.duplicate_data.date);
            setDate(new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate()
            ));
            setTaskType(TaskTypeDropdownItems.find((item: DropdownItem) => item.id === props.duplicate_data.task_type) || {id: '', display: ''});
            setWorkDescription(props.duplicate_data.work_description);
            setOtherExpensesDetail(props.duplicate_data.other_expenses_detail);
            setDayWorked(TimeEntryDayWorkedDropdownOptions.find((item: DropdownItem) => item.id === props.duplicate_data.day_worked + '') || {id: '', display: ''});
            setLodging(props.duplicate_data.lodging);
            setMeals(props.duplicate_data.meals);
            setMileage(props.duplicate_data.mileage);
            setCopiesDocuments(props.duplicate_data.copies_documents);
            setOtherExpenses(props.duplicate_data.other_expenses);
        }
    }, [props.duplicate_data, personnel, workorderOptions]);

    useEffect(() => {
        if (props.duplicate_data.id !== -1) {
            setLodging(props.duplicate_data.lodging);
            setMeals(props.duplicate_data.meals);
            setMileage(props.duplicate_data.mileage);
            setCopiesDocuments(props.duplicate_data.copies_documents);
            setOtherExpenses(props.duplicate_data.other_expenses);
        }
    }, [props.duplicate_data])

    // FILE UPLOAD
    const [fileLink, setFileLink] = React.useState('');
    const { 
        refetch: file_fetch,
        remove: file_remove
     } = useQuery('fileupload', async () => {
        let zip = new JSZip();
        for(let i = 0; i < files.length; i++) {
            const file_to_add = files[i][0];
            zip.file(file_to_add.name, await file_to_add.arrayBuffer());
        }
        const formData = new FormData();
        const zipFileBufferArray = await zip.generateAsync({type: 'arraybuffer'});
        formData.append('files', new Blob([zipFileBufferArray], {type: 'zip'}), 'formfiles.zip');
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/fileupload`, {
            method: 'POST',
            headers: {
                'usertoken': sessionDetails.access_token
            },
            body: formData
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
        onSuccess: (data) => {
            const link = data.DATA.file_url as string;
            setFileLink(link);
        },
        onError: (data) => {
            setFileLink("User's file upload failed.");
        }
    });

    // TIME ENTRY ADDING 
    const { 
        data: add_data,
        status: add_status, 
        refetch: add_refetch, 
        remove: add_remove 
    } = useQuery('timeentry_add', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entry`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    locked: false,
                    personnel_id: personnel.id,
                    workorder_id: workorder.id,
                    task_type: task_type.id,
                    date: date,
                    work_description: work_description,
                    lodging: lodging,
                    meals: meals,
                    mileage: mileage,
                    copies_documents: copies_documents,
                    other_expenses: other_expenses,
                    other_expenses_detail: other_expenses_detail,
                    day_worked: day_worked.id,
                    file_link: fileLink
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const add_loading = add_status !== 'success' || add_data.STATUS !== 'SUCCESS';

    useEffect(() => {
        if(fileLink.length > 0) {
            add_refetch();
        }
    }, [fileLink]);

    const setDateWrapper = (date: Date) => {
        setDate(date);
        if (props.useLockDate && lockDate >= new Date(date)) {
            setValidDate(false);
            setMessage(`Time entry must be for dates after: ${lockDateStr}`);
            return;
        } else {
            setValidDate(true);
            setMessage('');
        }
    }

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: 'Workorder',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: workorder,
            setValue: setWorkorder,
            dropdown_values: workorderOptions,
            disabled: get_workorder_loading || !!props.workorder
        }, {
            name: 'Personnel',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: personnel,
            setValue: setPersonnel,
            dropdown_values: personnelOptions,
            disabled: get_personnel_loading || props.user_permissions === UserPermissions.user || workorder.id.length === 0
        }, {
            name: 'Task Type',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: task_type,
            setValue: setTaskType,
            dropdown_values: TaskTypeDropdownItems
        }, {
            name: 'Date',
            attributeType: MenuAttributeTypes.date_input,
            set_value: date,
            setValue: setDateWrapper,
        }, {
            name: 'Day Worked',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: day_worked,
            setValue: setDayWorked,
            dropdown_values: TimeEntryDayWorkedDropdownOptions
        }, {
            name: 'Work Description',
            attributeType: MenuAttributeTypes.long_input,
            set_value: work_description,
            setValue: setWorkDescription,
        }, {
            name: 'Lodging',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: lodging,
            setValue: setLodging,
        }, {
            name: 'Meals',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: meals,
            setValue: setMeals,
        }, {
            name: '# Miles',
            attributeType: MenuAttributeTypes.twodec_input,
            set_value: mileage,
            setValue: setMileage,
        }, {
            name: 'Copies/Documents',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: copies_documents,
            setValue: setCopiesDocuments,
        }, {
            name: 'Other Expenses',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: other_expenses,
            setValue: setOtherExpenses,
        }, {
            name: 'Other Expenses Detail',
            attributeType: MenuAttributeTypes.short_input,
            set_value: other_expenses_detail,
            setValue: setOtherExpensesDetail,
        }, {
            name: "Files",
            attributeType: MenuAttributeTypes.file_upload,
            set_value: files,
            setValue: setFiles,
            multi: true
        }
    ];

    const onSubmit = () => {
        // if no files selected, set file link to constant and return
        if(files.length === 0 || (files.length === 1 && files[0] === null)) { 
            setFileLink('.');
        } else {
            file_fetch();
        }
        
    }

    useEffect(() => {
        if (!add_loading) {
            setMessage('');
            add_remove();
            file_remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [add_loading, props, add_remove])

    const [saveDisabled, setSaveDisabled] = React.useState(true);

    useEffect(() => {
        const disabled = !validDate || lockDateStr.length === 0 || personnel.id.length === 0 || workorder.id.length === 0 || task_type.id.length === 0 || 
        day_worked.id.length === 0 || work_description.length === 0 || lodging < 0 || Number.isNaN(lodging) || Number.isNaN(meals) || Number.isNaN(mileage) || Number.isNaN(other_expenses) ||
        meals < 0 || mileage < 0 || copies_documents < 0 || other_expenses < 0 || (other_expenses > 0 && other_expenses_detail.length === 0);
        if (disabled) {
            setSaveDisabled(disabled);
            return;
        }
        const fileSubmissionValid = 
            files.length === 0 ||
            (files.length === 1 && files[0] === null) || 
            (files.find((list) => list.length === 0) === undefined);
        setSaveDisabled(!fileSubmissionValid);
    }, [validDate, ticker, personnel, workorder, task_type, day_worked, work_description, lodging, meals, mileage, copies_documents, other_expenses, other_expenses_detail, date, lockDate])

    const isSaveButtonDisabled = (): boolean => saveDisabled;
        

    return (
        <FormModal 
            title='Add Time Entry'
            form_strategy='Add'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
            message={message.length > 0 ? message : undefined}
        />
    );
}

export const TimeEntryDayWorkedDropdownOptions: DropdownItem[] = [
    {id: '0', display: "0%"},
    {id: '0.125', display: "12.5%"},
    {id: '0.25', display: "25%"},
    {id: '0.375', display: "37.5%"},
    {id: '0.5', display: "50%"},
    {id: '0.625', display: "62.5%"},
    {id: '0.75', display: "75%"},
    {id: '0.875', display: "87.5%"},
    {id: '1', display: "100%"},
    {id: '1.125', display: "112.5%"},
    {id: '1.25', display: "125%"},
    {id: '1.375', display: "137.5%"},
    {id: '1.5', display: "150%"}
];

export default TimeEntryAddModal;