import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { DropdownItem, MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import FormModal from '../../modal/FormModal';
import usStates from './states';
import { County } from './CountyTable';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';

const queryClient = new QueryClient()

interface CountyUpdateModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: County;
}

const dropdown_options = usStates.map((state) => ({
    id: state.abbreviation,
    display: state.name
} as DropdownItem))

const CountyUpdateModal = (props: CountyUpdateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <CountyUpdateModalInner {...props}/>
    </QueryClientProvider>
);

const CountyUpdateModalInner = (props: CountyUpdateModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

    const [nameValue, setNameValue] = React.useState(props.data.county_name);
    const [stateCodeValue, setStateCodeValue] = React.useState(
        {id: '', display: ''} as DropdownItem);

    useEffect(() => {
        setNameValue(props.data.county_name);
        const dropdownItem = dropdown_options.find((option) => option.id === props.data.state_code);
        setStateCodeValue(dropdownItem || {id: '', display: ''})
    }, [props.data]);

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "County Name",
            attributeType: MenuAttributeTypes.short_input,
            set_value: nameValue,
            setValue: setNameValue
        },
        {
            name: "State",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: stateCodeValue,
            setValue: setStateCodeValue,
            dropdown_values: dropdown_options
        }
    ];

    // disabled if there exists a form element w/ length 0
    const isSaveButtonDisabled = (): boolean =>
        nameValue.length === 0 || stateCodeValue.id.length !== 2 || (nameValue === props.data.county_name && stateCodeValue.id === props.data.state_code);

    // submit request stuff
    const { data, status, refetch, remove } = useQuery('client_add', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/county`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.id,
                    county_name: nameValue,
                    state_code: stateCodeValue.id
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            setNameValue('');
            setStateCodeValue({
                id: '',
                display: '',
            });
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Update County'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default CountyUpdateModal;