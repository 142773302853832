import React from 'react';
import { Stack, Table, Thead, Tbody, Tr, Th, Td, Skeleton, Button, ButtonGroup, IconButton, Heading, Center, Tooltip } from "@chakra-ui/react"
import { GrAdd } from "react-icons/gr";
import { FaTrashAlt, FaEdit, FaLock, FaUnlock, FaCopy } from "react-icons/fa";
import { BiArchiveOut, BiArchiveIn } from "react-icons/bi";
import { FiCopy, FiUpload } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { CgExport } from "react-icons/cg";
import "./CustomTable.css";

export interface TableColumn<T> {
    id: string;
    title: string;
    render_strategy: (data: T) => JSX.Element;
    center?: boolean;
}

export interface CustomTableProps<T> {
    tableHeader: string;
    columns: TableColumn<T>[];
    data: T[];
    loading: boolean;
    onAdd?: () => void;
    onDelete?: (data: T) => void;
    onEdit?: (data: T) => void;
    onUpload?: (data: T) => void;
    onExport?: () => void;
    onLockToggle?: (data: T) => void;
    onDuplicate?: (data: T) => void;
    isRowLocked?: (data: T) => boolean;
    disableRow?: (data: T) => boolean;
    onArchive?: (data: T) => void;
    isRowArchived?: (data: T) => boolean;
    onLoadMore?: () => void;
}

const CustomTable = <T, >(props: CustomTableProps<T>): JSX.Element => {
    const [loadMoreButtonDisabled, setLoadMoreButtonDisabled] = React.useState(false);

    const onLoadMoreWithDisabled = !props.onLoadMore
        ? () => {}
        : () => {
            props.onLoadMore && props.onLoadMore();
            setLoadMoreButtonDisabled(true);
            setTimeout(() => setLoadMoreButtonDisabled(false), 3250);
        }

    const updatedCols = (!props.onDelete && !props.onEdit) ? props.columns : [
        ...props.columns,
        {
            id: 'buttons',
            title: '',
            render_strategy: (data: T) => {
                const disabledRow: boolean = (props.disableRow && props.disableRow(data)) || false;
                return (
                    <ButtonGroup variant="outline" spacing="6" className="tableButtonGroup" isAttached>
                        {props.onLockToggle && props.isRowLocked && <Tooltip label={props.isRowLocked(data) ? 'Unlock' : 'Lock'}>
                            <IconButton 
                                aria-label={`${props.isRowLocked(data) ? 'Unlock' : 'Lock'} row`}
                                icon={props.isRowLocked(data) ? <FaUnlock /> : <FaLock />}
                                className='lockToggleButton'
                                size='sm'
                                onClick={() => props.onLockToggle && props.onLockToggle(data)}
                            />
                        </Tooltip>}
                        {props.onArchive && props.isRowArchived && <Tooltip label={props.isRowArchived(data) ? 'Unarchive' : 'Archive'}>
                            <IconButton
                                aria-label={`${props.isRowArchived(data) ? 'Unarchive' : 'Archive'} row`}
                                icon={props.isRowArchived(data) ? <BiArchiveOut /> : <BiArchiveIn />}
                                className='lockToggleButton'
                                size='sm'
                                onClick={() => props.onArchive && props.onArchive(data)}
                            />
                        </Tooltip>}
                        {props.onDuplicate && <Tooltip label='Duplicate'>
                            <IconButton
                                aria-label='Duplicate Row'
                                icon={<FiCopy />}
                                size='sm'
                                onClick={() => props.onDuplicate && props.onDuplicate(data)}
                                backgroundColor='white'
                                color='black'
                            />
                        </Tooltip>}
                        {props.onUpload && <Tooltip label='Upload Files'>
                            <IconButton
                                disabled={disabledRow}
                                aria-label='Upload Files'
                                icon={<FiUpload />}
                                size='sm'
                                onClick={() => props.onUpload && props.onUpload(data)}
                            />
                        </Tooltip>}
                        {props.onEdit && <Tooltip label='Edit'>
                            <IconButton
                                disabled={disabledRow}
                                aria-label='Edit row'
                                icon={<FaEdit />}
                                size='sm'
                                onClick={() => props.onEdit && props.onEdit(data)}
                            />
                        </Tooltip>}
                        {props.onDelete && <Tooltip label='Delete'>
                            <IconButton
                                disabled={disabledRow}
                                aria-label='Delete row'
                                icon={<FaTrashAlt />}
                                size='sm'
                                onClick={() => props.onDelete && props.onDelete(data)}
                            />
                        </Tooltip>}
                    </ButtonGroup>
                );
            }
        } as TableColumn<T>
    ];
    const numColumns = props.columns.length;
    const tableBody = props.loading ?
        Array(5).fill('').map((el: string, i: number) => <Tr key={i}>{updatedCols.map((col: TableColumn<T>, i: number) => <Td key={i}><Skeleton>a</Skeleton></Td>)}</Tr>):
        props.data.map(
            (data: T, i: number) => 
                <Tr key={i} className={props.disableRow && props.disableRow(data) ? 'disabledRow' : undefined}>
                    {updatedCols.map((col: TableColumn<T>, i: number) => <Td className='tableCell' width={`${100/numColumns}%`} key={i}> {col.render_strategy(data)}</Td>)}
                </Tr>
        );
    return (
        <Stack spacing={8}>
            <div className="tableHeader">
                <ButtonGroup variant="outline" spacing="6" className="tableButtonGroup" isAttached>
                    {props.onAdd && 
                    <Tooltip label='Add'>
                        <Button leftIcon={<GrAdd />} colorScheme="green" size="sm" onClick={props.onAdd} disabled={props.loading}>Add</Button>
                    </Tooltip>
                    }
                    {props.onExport &&
                    <Tooltip label='Export'>
                        <Button leftIcon ={<CgExport />} size='sm' onClick={props.onExport} disabled={props.loading}>Export</Button>    
                    </Tooltip>}
                </ButtonGroup>
                <Center><Heading className="tableHeaderHeading" size="md">{props.tableHeader}</Heading></Center>
            </div>
            <Table variant="simple" size="sm" className='customTableData'>
                <Thead>
                    <Tr>
                        { updatedCols.map((column: TableColumn<T>, i: number) => <Th className='tableCell' width={`${100/numColumns}%`} key={i} textAlign={column.center ? 'center' : 'left'}> {column.title} </Th> )}
                    </Tr>
                </Thead>
                <Tbody>
                    { tableBody }
                </Tbody>
            </Table>
            { props.onLoadMore &&
                <Tooltip label='Load More'>
                    <Button disabled={loadMoreButtonDisabled} leftIcon={<AiOutlinePlus />} colorScheme='gray' onClick={onLoadMoreWithDisabled}>Load More</Button>
                </Tooltip>
            }
        </Stack>
    );
}

export default CustomTable;