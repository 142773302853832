import { Center, Grid, GridItem, Heading } from '@chakra-ui/react';
import React from 'react';
import { readableDate } from '../../common/utils';
import { getReadableJobType, getReadableWorkorderStatus } from '../workorders/WorkorderTable';
import { DetailDisplay, WorkorderDetailUser } from './WorkorderDetail';
import "./WorkorderPage.css";

interface WorkorderDetailProps {
    workorder: WorkorderDetailUser;
}

const WorkorderDetailDisplayUser = (props: WorkorderDetailProps): JSX.Element => {
    const data = props.workorder;
    
    const gridItemProps = {
        margin: '1em',
    };

    return (
        <>
            <Center>
                <Heading size="lg">
                    {data.workorder_name} for {data.client_name}
                </Heading>
            </Center>
            <Grid
                templateRows="repeat(3, 1fr)"
                templateColumns="repeat(2, 1fr)"
                gap={4}
            >
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Location"
                        additional_lines={data.counties.map(county => `${ county.county_name }, ${ county.state_code }`)}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Status"
                        body={getReadableWorkorderStatus(data.workorder_status as string)}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Job Type"
                        body={getReadableJobType(data.job_type as string)}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Date Received"
                        body={readableDate(new Date(data.date_received))}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Date Due"
                        body={readableDate(new Date(data.date_due))}
                    />
                </GridItem>
            </Grid>
        </>
    );
};

export default WorkorderDetailDisplayUser;
