import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import AdminManageTable from './AdminManageTable';

const queryClient = new QueryClient();

interface AdminManagePageProps {}

const AdminManagePage = (props: AdminManagePageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <AdminManagePageInner {...props}/>
    </QueryClientProvider>
);

const AdminManagePageInner = (props: AdminManagePageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { data, status, refetch } = useQuery('admin_manage_page', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/personnel?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    return (
        <div>
            <AdminManageTable
                loading={loading}
                data={loading ? [] : data.DATA.personnel}
                refresh={refetch}
            />
        </div>
    );
}

export default AdminManagePage;