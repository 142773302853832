import React, { useState } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import App, { UserPermissions, UserSessionDetails } from './App';
import { get_microsoft_auth_response_from_url, MicrosoftAuthResponse } from './common/utils';
import LoginPage from './auth/LoginPage';

const queryClient = new QueryClient();
const cookie_name = 'wolcott-timetracking-user-id';

interface AppProps {
    browser_url: string;
    redirectTo: (location: string) => void;
    session_details: UserSessionDetails;
}

const AppAuthWrapper = (props: AppProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <AppAuthInner {...props}/>
    </QueryClientProvider>
);

const getCookieExpiration = (): Date => {
    const today = new Date();
    const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const tomorrowStart = new Date(todayStart.getTime() + 86400000);
    return tomorrowStart;
};

const AppAuthInner = (props: AppProps): JSX.Element => {
    const [cookies, setCookies, removeCookies] = useCookies([cookie_name]);
    const getCookie = () => cookies[cookie_name];
    const setCookie = (value: string, options?: CookieSetOptions) => setCookies(cookie_name, value, options);
    const removeCookie = () => removeCookies(cookie_name);
    
    const [error, setError] = React.useState('');
    const [userInfo, setUserInfo] = React.useState({
        permission_level: UserPermissions.user,
        access_token: '',
        backend: {host: '', port: ''}
    } as UserSessionDetails);
    
    const microsoft_auth_response: MicrosoftAuthResponse = get_microsoft_auth_response_from_url(props.browser_url);
    const user_completed_microsoft_auth: boolean = !!microsoft_auth_response.id_token && !!microsoft_auth_response.state;
    const accessfetch_params = user_completed_microsoft_auth 
    ? `id_token=${microsoft_auth_response.id_token}&state=${microsoft_auth_response.state}` 
    : `state=${getCookie()}`;
    const [accessfetch_hasFetched, set_accessfetch_hasFetched] = useState(false);
    const { data: accessfetch_data, refetch: accessfetch } = useQuery('accessfetch_get', async () => {
    const res = await fetch(`${props.session_details.backend.host}:${props.session_details.backend.port}/accessfetch?${accessfetch_params}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return res.json();
        }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (data) => {
            if (data.STATUS !== 'SUCCESS') {
                removeCookie();
                props.redirectTo('/error');
            return;
            }
            const nextUserInfo: UserSessionDetails = {
            permission_level: data.DATA.permission_level,
            access_token: data.DATA.access_token,
            backend: props.session_details.backend
            };
            setUserInfo(nextUserInfo);
            setCookie(nextUserInfo.access_token, {
                expires: getCookieExpiration()
            });
        }
    });
    if (error.length > 0){
        return <p>error: {error}</p>;
    }else if (!microsoft_auth_response.state && !getCookie()) {
        return <LoginPage sessionDetails={props.session_details} redirectTo={props.redirectTo} />;
    }else if (userInfo.access_token.length === 0) {
        if (!accessfetch_hasFetched) {
            accessfetch();
            set_accessfetch_hasFetched(true);
        }
        return <p> Loading... </p>;
    } else {
        return <App {...props} loggedin_user={userInfo}/>;
    }
}

export default AppAuthWrapper;