import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import FormModal from '../../modal/FormModal';
import { Workorder } from './WorkorderTable';

const queryClient = new QueryClient();

interface WorkorderDeleteModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: Workorder;
}

const WorkorderDeleteModal = (props: WorkorderDeleteModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderDeleteModalForm {...props}/>
    </QueryClientProvider>
);


const WorkorderDeleteModalForm = (props: WorkorderDeleteModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    // submit request stuff
    const { data, status, refetch, remove } = useQuery('client_add', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.id
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Delete Workorder'
            form_strategy='Delete'
            menuAttributes={[]}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={() => false}
            onSave={onSubmit}
            message='Are you sure you want to delete this workorder? This action cannot be reversed.'
        />
    )
};

export default WorkorderDeleteModal;