import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../../App';
import PersonnelTableAdmin from './PersonnelTableAdmin';

const queryClient = new QueryClient()

interface PersonnelDisplayAdminProps {
    workorder_id: string;
}

const PersonnelDisplayAdmin = (props: PersonnelDisplayAdminProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <PersonnelDisplayInner {...props}/>
    </QueryClientProvider>
);

const PersonnelDisplayInner = (props: PersonnelDisplayAdminProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder_assignment_with_rates?access_token=${sessionDetails.access_token}&workorder_id=${props.workorder_id}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    
    
    return (
        <div>
            <PersonnelTableAdmin
                loading={loading}
                data={loading ? [] : data.DATA}
                workorder_id={props.workorder_id}
                refresh={refetch}
            />
        </div>
    );
};

export default PersonnelDisplayAdmin;