import React from "react";
import { Paper } from "@material-ui/core";

import {
    createStyles,
    withStyles,
    WithStyles,
  } from '@material-ui/core/styles';

const styles = createStyles({
    paper: {
        width: '100%',
        margin: 'auto',
        marginBottom: '2em',
    },
});


export interface CommonPaperProps extends WithStyles<typeof styles> {
    content: JSX.Element;
    elevation?: number;
  }
const CommonPaper = (props: CommonPaperProps): JSX.Element => 
  <Paper className={props.classes.paper} elevation={props.elevation} >{ props.content }</Paper>;

export default withStyles(styles)(CommonPaper)