import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import FormModal from '../../modal/FormModal';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';

const queryClient = new QueryClient()

interface ClientContactAddModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    client_id: string;
}

const ClientContactAddModal = (props: ClientContactAddModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <ClientContactAddModalInner {...props}/>
    </QueryClientProvider>
)

const ClientContactAddModalInner = (props: ClientContactAddModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const [nameValue, setNameValue] = React.useState("");
    const [phoneValue, setPhoneValue] = React.useState("");
    const [addressValue, setAddressValue] = React.useState("");
    const [emailValue, setEmailValue] = React.useState("");

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Name",
            attributeType: MenuAttributeTypes.short_input,
            set_value: nameValue,
            setValue: setNameValue
        },
        {
            name: "Phone",
            attributeType: MenuAttributeTypes.short_input,
            set_value: phoneValue,
            setValue: setPhoneValue
        },
        {
            name: "Address",
            attributeType: MenuAttributeTypes.short_input,
            set_value: addressValue,
            setValue: setAddressValue
        },
        {
            name: "Email",
            attributeType: MenuAttributeTypes.short_input,
            set_value: emailValue,
            setValue: setEmailValue
        }
    ];

    // disabled if there exists a form element w/ length 0
    const isSaveButtonDisabled = (): boolean => 
        [nameValue, phoneValue, addressValue, emailValue].find((el: string) => el.length === 0) !== undefined;
    
    // submit request stuff
    const { data, status, refetch, remove } = useQuery('client_add', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/client_contact`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    client_id: props.client_id,
                    name: nameValue,
                    phone: phoneValue,
                    address: addressValue,
                    email: emailValue 
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            [setNameValue, setPhoneValue, setAddressValue, setEmailValue].forEach(
                (set: (val: string) => void) => set('')
            );
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Add Client Contact'
            form_strategy='Add'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default ClientContactAddModal