import React from 'react';
import { InternalLink } from '../../common/components';
import { readableDate } from '../../common/utils';
import { TimeEntry } from "../../content/time_entries/TimeEntryTable";
import CustomTable, { TableColumn } from '../../table/CustomTable';
import "./AnalysisPage.css";

interface AnalysisDisplayProps {
    data: TimeEntry[];
}

interface PersonnelData {
    name: string;
    id: string;
    latestEntryDate: string;
    data: TimeEntry[];
}

const getPersonnelDataFromEntries = (entries: TimeEntry[]): {[id: string]: PersonnelData;} => {
    const map: {[id: string]: PersonnelData;} = {};

    entries.forEach((entry: TimeEntry) => {
        if (!(entry.personnel_id + "" in map)) {
            map[entry.personnel_id + ""] = {
                name: entry.personnel_name,
                id: entry.personnel_id + '',
                data: [entry],
                latestEntryDate: "TODO"
            }
        } else {
            map[entry.personnel_id].data.push(entry);
        }
    });
    Object.keys(map).forEach((key: string) => {
        map[key].latestEntryDate = readableDate(new Date(map[key].data[0].date as unknown as string));
    });
    return map;
}

interface WorkorderData {
    name: string;
    id: string;
    latestEntryDate: string;
    data: TimeEntry[];
}

const getWorkorderDataFromEntries = (entries: TimeEntry[]): {[id: string]: WorkorderData;} => {
    const map: {[id: string]: WorkorderData;} = {};
    entries.forEach((entry: TimeEntry) => {
        if (!(entry.workorder_id + "" in map)) {
            map[entry.workorder_id + ""] = {
                name: entry.workorder_name,
                id: entry.workorder_id + '',
                data: [entry],
                latestEntryDate: ""
            }
        } else {
            map[entry.workorder_id].data.push(entry);
        }
    });
    Object.keys(map).forEach((key: string) => {
        map[key].latestEntryDate = readableDate(new Date(map[key].data[0].date as unknown as string));
    });
    return map;
}

const getDaysBilled = (data: TimeEntry[]): number => 
    data.map(data => data.day_worked).reduce((a, b) => a + b, 0);

interface PersonnelStat {
    name: string;
    id: string;
    daysBilled: number;
    latestEntryDate: string;
}

interface WorkorderStat {
    name: string;
    id: string;
    daysBilled: number;
    latestEntryDate: string;
}

interface StatisticsResponse {
    totalDaysBilled: number;
    personnelData: PersonnelStat[];
    workorderData: WorkorderStat[];
}

const getStatistics = (data: TimeEntry[]): StatisticsResponse => {
    const personnelMap = getPersonnelDataFromEntries(data);
    const workorderMap = getWorkorderDataFromEntries(data);

    const totalDaysBilled = getDaysBilled(data);

    const personnelData = Object.values(personnelMap).map((data: PersonnelData): PersonnelStat => {
        return {
            name: data.name,
            id: data.id,
            latestEntryDate: data.latestEntryDate,
            daysBilled: getDaysBilled(data.data)
        }
    });

    const workorderData = Object.values(workorderMap).map((data: WorkorderData): WorkorderStat => {
        return {
            name: data.name,
            id: data.id,
            latestEntryDate: data.latestEntryDate,
            daysBilled: getDaysBilled(data.data)
        }
    });

    return {
        totalDaysBilled,
        personnelData,
        workorderData
    }
}

const workorderStatColumns: TableColumn<WorkorderStat>[] = [
    {
        id: 'name',
        title: 'Name',
        render_strategy: (wo: WorkorderStat) => 
            <p><InternalLink content={wo.name} route={`/workorders/${wo.id}`}/></p>
    },
    {
        id: 'daysBilled',
        title: 'Days Billed',
        render_strategy: (wo: WorkorderStat) => <p>{wo.daysBilled}</p>
    },
    {
        id: 'latestEntryDate',
        title: 'Latest Entry Date',
        render_strategy: (wo: WorkorderStat) => <p>{wo.latestEntryDate}</p>
    }
]

const personnelStatColumns: TableColumn<PersonnelStat>[] = [
    {
        id: 'name',
        title: 'Name',
        render_strategy: (personnel: PersonnelStat) => 
            <p><InternalLink content={ personnel.name } route={`/personnel/${personnel.id}`}/></p>
    },
    {
        id: 'daysBilled',
        title: 'Days Billed',
        render_strategy: (personnel: PersonnelStat) => <p>{personnel.daysBilled}</p>
    },
    {
        id: 'latestEntryDate',
        title: 'Latest Entry Date',
        render_strategy: (personnel: PersonnelStat) => <p>{personnel.latestEntryDate}</p>
    }
]


const AnalysisDisplay = (props: AnalysisDisplayProps): JSX.Element => {
    const stats = getStatistics(props.data);
    return <div className="statsContainer">
        <div>Total # of timesheets: {props.data.length}</div>
        <div>Total # of days billed: {stats.totalDaysBilled}</div>
        <div>
            <CustomTable
                tableHeader={'Personnel Data'}
                columns={personnelStatColumns}
                loading={false}
                data={stats.personnelData}
            />
        </div>
        <div>
            <CustomTable 
                tableHeader='Workorder Data'
                columns={workorderStatColumns}
                loading={false}
                data={stats.workorderData}
            />
        </div>
    </div>;
};

export default AnalysisDisplay;