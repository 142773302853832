import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails } from '../App';

const queryClient = new QueryClient();

interface LoginPageProps {
    sessionDetails: UserSessionDetails;
    redirectTo: (location: string) => void;
}


const LoginPage = (props: LoginPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <LoginPageInner {...props}/>
    </QueryClientProvider>
);

const LoginPageInner = (props: LoginPageProps): JSX.Element => {
    const { 
        data: loginlink_data,
        status: loginlink_status, 
    } = useQuery('loginlink_get', async () => {
        const res = await fetch(`${props.sessionDetails.backend.host}:${props.sessionDetails.backend.port}/login_link`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loginlink_loading = loginlink_status !== 'success' || loginlink_data.STATUS !== 'SUCCESS';
    
    if (loginlink_loading) {
        return <p>loading</p>;
    }

    props.redirectTo(loginlink_data.DATA);

    return <p>Redirecting to { loginlink_data.DATA }</p>
};

export default LoginPage;