import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import CountyTable from './CountyTable';

const queryClient = new QueryClient()

interface CountyPageProps {}

const CountyPage = (props: CountyPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <CountyPageInner {...props}/>
    </QueryClientProvider>
);

const CountyPageInner = (props: CountyPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/county?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    
    return (
        <div>
            <CountyTable
                loading={loading}
                data={loading ? [] : data.DATA}
                refresh={refetch}
            />
        </div>
    )
};

export default CountyPage;