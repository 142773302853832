import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ClientContactPage from './content/client_contact/ClientContactPage';
import ClientPage from './content/client/ClientPage';
import WorkorderPageUser from './content/workorders/WorkorderPageUser';
import WorkorderPageAdmin from './content/workorders/WorkorderPageAdmin';
import CountyPage from './content/county/CountyPage';
import TimeEntryUserPage from './content/time_entries/TimeEntryPageUser';
import TimeEntryAdminPage from './content/time_entries/TimeEntryPageAdmin';
import WorkorderDetailPageUser from './content/workorder/WorkorderDetailPageUser';
import WorkorderDetailPageAdmin from './content/workorder/WorkorderDetailPageAdmin';
import AdminPage from './admin_panel/AdminPage';
import WorkorderPageAdminArchived from './content/workorders/WorkorderPageAdminArchived';
import AdminManagePage from './admin_panel/admin_manager/AdminManagePage';
import { NavigationCategories, NavigationTab } from './nav/Navigator';

import ListAltIcon from '@material-ui/icons/ListAlt';
import TocIcon from '@material-ui/icons/Toc';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LockIcon from '@material-ui/icons/Lock';
import Paperbase from './nav/Paperbase';
import TimeEntryAdminPageSinglePersonnel from './content/time_entries/TimeEntryPageAdminSinglePersonnel';
import AnalysisPage from './admin_panel/analysis/AnalysisPage';


export enum UserPermissions {
  user='user',
  admin='admin'
}

export interface BackendDetails {
  host: string;
  port: string;
}

export interface UserSessionDetails {
  permission_level: UserPermissions;
  access_token: string;
  backend: BackendDetails;
}

export const UserSessionDetailsContext = React.createContext({
  permission_level: UserPermissions.user,
  access_token: '',
  backend: {host: '', port: ''}
} as UserSessionDetails);

interface AppProps {
  browser_url: string;
  redirectTo: (location: string) => void;
  loggedin_user: UserSessionDetails;
}


const App = (props: AppProps): JSX.Element => {

  const categories: NavigationCategories[] = [
    {
      id: 'Time Tracking',
      admin_only: false,
      children: [
        { id: 'My Timesheet', icon: <ListAltIcon />, tabId: NavigationTab.MyTimesheet, route: '/timesheet', active: false },
        { id: 'My Workorders', tabId: NavigationTab.MyWorkorders, icon: <TocIcon />, route: '/my_workorders', active: false },
      ],
    },
    {
      id: 'Admin',
      admin_only: true,
      children: [
        { id: 'All Timesheets', icon: <ListAltIcon />, tabId: NavigationTab.AllTimesheets, route: '/timesheets', active: false },
        { id: 'All Workorders', icon: <TocIcon />, tabId: NavigationTab.AllWorkorders, route: '/workorders', active: false },
        { id: 'Clients', icon: <PeopleAltIcon />, tabId: NavigationTab.Clients, route: '/clients', active: false },
        { id: 'Counties', icon: <LocationOnIcon />, tabId: NavigationTab.Counties, route: '/county', active: false },
        { id: 'Admin Panel', icon: <LockIcon />, tabId: NavigationTab.AdminPanel, route: '/admin_panel', active: false },
      ],
    },
  ];


  return (
    <>
      <UserSessionDetailsContext.Provider value={props.loggedin_user}>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/timesheet" />
              </Route>
              
              <Route exact path="/timesheet">
                <Paperbase
                  currentPage={NavigationTab.MyTimesheet} 
                  categories={categories}
                  content={<TimeEntryUserPage/>}
                />
              </Route>
              <Route exact path="/my_workorders">
                <Paperbase
                  currentPage={NavigationTab.MyWorkorders} 
                  categories={categories}
                  content={<WorkorderPageUser/>}
                />
              </Route>
              <Route path = "/my_workorders/:workorder_id">
                <Paperbase
                  currentPage={NavigationTab.MyWorkorders} 
                  categories={categories}
                  content={<WorkorderDetailPageUser />}
                />
              </Route>


              <Route exact path="/timesheets">
                <Paperbase
                  currentPage={NavigationTab.AllTimesheets} 
                  categories={categories}
                  content={<TimeEntryAdminPage />}
                />
              </Route>
              <Route path = "/personnel/:personnel_id">
                <Paperbase 
                  currentPage={NavigationTab.AllTimesheets}
                  categories={categories}
                  content={<TimeEntryAdminPageSinglePersonnel />}
                />
              </Route>
              <Route exact path="/workorders">
                <Paperbase
                  currentPage={NavigationTab.AllWorkorders} 
                  categories={categories}
                  content={<WorkorderPageAdmin/>}
                />
              </Route>
              <Route path = "/workorders/:workorder_id">
                <Paperbase
                  currentPage={NavigationTab.AllWorkorders} 
                  categories={categories}
                  content={<WorkorderDetailPageAdmin />}
                  paper={false}
                />
              </Route>
              <Route exact path="/clients">
                <Paperbase
                  currentPage={NavigationTab.Clients} 
                  categories={categories}
                  content={<ClientPage redirectTo={props.redirectTo}/>}
                />
              </Route>
              <Route path="/client_contacts/:client_id">
                <Paperbase
                  currentPage={NavigationTab.Clients} 
                  categories={categories}
                  content={<ClientContactPage/>}
                />
              </Route>
              <Route exact path="/county">
                <Paperbase
                  currentPage={NavigationTab.Counties} 
                  categories={categories}
                  content={<CountyPage/>}
                />
              </Route>
              <Route exact path="/admin_panel">
                <Paperbase
                  currentPage={NavigationTab.AdminPanel} 
                  categories={categories}
                  content={<AdminPage/>}
                />
              </Route>
              <Route exact path="/admin_panel/archived_workorders">
                <Paperbase
                  currentPage={NavigationTab.AdminPanel} 
                  categories={categories}
                  content={<WorkorderPageAdminArchived />}
                />
              </Route>
              <Route exact path = "/admin_panel/admin_management">
                <Paperbase
                  currentPage={NavigationTab.AdminPanel} 
                  categories={categories}
                  content={<AdminManagePage/>}
                />
              </Route>
              <Route exact path = "/admin_panel/analysis">
                <Paperbase
                  currentPage={NavigationTab.AdminPanel} 
                  categories={categories}
                  content={<AnalysisPage />}
                />
              </Route>
            </Switch>
          </Router>
        </div>
      </UserSessionDetailsContext.Provider>
    </>
  );
};

export default App;
