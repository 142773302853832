import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from "@chakra-ui/react"
import './index.css';
import App, { UserPermissions, UserSessionDetails } from './app/App';
import AppAuthWrapper from './app/AppAuthWrapper';

const redirectBrowswerTo = (location: string) => window.location.href = location;
const permanent_session_details: UserSessionDetails = {
  permission_level: UserPermissions.admin,
  access_token: 'abcde',
  backend: {host: 'https://api.tps.wolcottllc.com', port: '443'},
};
const url = window.location.href;
const error_suffix = '/error';
const isOnErrorPage = ():boolean => {
  const ends_with_error = url.substring(window.location.href.length - error_suffix.length) === error_suffix
  let slash_count: number = 0;
  const purl = url.substring('https://'.length);
  for(var i = 0; i < purl.length; i++) {
    if (purl.charAt(i) !== '/') continue;
    if (++slash_count > 1) return false;
  }
  return ends_with_error;
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      { isOnErrorPage()
        ? <p onClick={() => redirectBrowswerTo('/')}> Login failed. Click here to try again. </p>
        : <AppAuthWrapper 
            browser_url={window.location.href}
            redirectTo={redirectBrowswerTo}
            session_details={permanent_session_details}
          />
      }
        
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

//reportWebVitals();
