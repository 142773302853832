import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import FormModal from '../../modal/FormModal';
import { DropdownItem, MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import { Client } from '../client/ClientTable';
import { ClientContact } from '../client_contact/ClientContactTable';
import { County } from '../county/CountyTable';
import { JobTypeDropdownItems, Workorder, WorkorderStatusDropdownItems } from './WorkorderTable';

const queryClient = new QueryClient();

interface WorkorderUpdateModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: Workorder;
}

const WorkorderUpdateModal = (props: WorkorderUpdateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderFormModal {...props} />
    </QueryClientProvider>
);

interface WorkorderFormModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: Workorder;
}

const WorkorderFormModal = (props: WorkorderFormModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const [name, setName] = React.useState('');
    const [client, setClient] = React.useState({
        id: '',
        display: '',
    } as DropdownItem);
    const [clientOptions, setClientOptions] = React.useState([] as DropdownItem[]);
    const [clientContact, setClientContact] = React.useState({
        id: '',
        display: '',
    } as DropdownItem);
    const [clientContactOptions, setClientContactOptions] = React.useState([] as DropdownItem[]);
    const [counties, setCounties] = React.useState([{
        id: '',
        display: '',
    } as DropdownItem]);
    const [numCounties, setNumCounties] = React.useState(0);
    const [countiesChanged, setCountiesChanged] = React.useState(false);
    const [countyOptions, setCountyOptions] = React.useState([] as DropdownItem[]);
    const [woStatus, setWOStatus] = React.useState({
        id: '',
        display: '',
    } as DropdownItem);
    const [jobType, setJobType] = React.useState({
        id: '',
        display: '',
    } as DropdownItem);
    const [dateReceived, setDateReceived] = React.useState(new Date());
    const [dateDue, setDateDue] = React.useState(new Date());
    const [estimate_amount, setEstimateAmount] = React.useState<number | undefined>(Number.NaN);
    useEffect(() => {
        setEstimateAmount(props.data.estimate_amount);
    }, [props.data]);
    
    // COUNTIES FETCHING
    const { 
        data: get_counties_data,
        status: get_counties_status, 
    } = useQuery('get_counties', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/county?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const options = data.DATA.map((county: County) => ({
                id: county.id + '',
                display: county.county_name + ', ' + county.state_code
            }));
            setCountyOptions(options);
            const selectedCounties = props
                .data
                .counties
                .map(selected_county =>
                    options.find((county: DropdownItem) => 
                        county.id === (selected_county.id + '')) || {id: '', display: ''}
                );
            console.log(`SELECTED COUNTIES`);
            console.log(selectedCounties);
            setCounties(selectedCounties);
            //setCounty((options).find((county: DropdownItem) => county.id === (props.data.county_id + '')) || {id: '', display: ''});
        }
    });
    const get_counties_loading = get_counties_status !== 'success' || get_counties_data.STATUS !== 'SUCCESS';

    // CLIENTS FETCHING
    const { 
        data: get_clients_data,
        status: get_clients_status, 
    } = useQuery('get_clients', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/clients?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const options = data.DATA.map((client: Client) => ({
                id: client.id + '',
                display: client.name
            }));
            setClientOptions(options);
            setClient((options).find((client: DropdownItem) => client.id === (props.data.client_id + '')) || {id: '', display: ''});
        }
    });
    const get_clients_loading = get_clients_status !== 'success' || get_clients_data.STATUS !== 'SUCCESS';

    // CLIENT CONTACT WRAPPER
    const { 
        data: get_clientcontacts_data,
        status: get_clientcontacts_status,
        refetch: get_clientcontacts_refetch
    } = useQuery('get_clientcontacts', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/client_contact?access_token=${sessionDetails.access_token}&client_id=${client.id}`, {
            method: 'GET',
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
        onSuccess: (data) => {
            const options = data.DATA.client_contacts.map((contact: ClientContact) => ({
                id: contact.id + '',
                display: contact.name
            }));
            setClientContactOptions(options);
            setClientContact((options).find((clientContact: DropdownItem) => clientContact.id === (props.data.client_contact_id + '')) || {id: '', display: ''});
        }
    });
    const get_clientcontacts_loading = get_clientcontacts_status !== 'success' || get_clientcontacts_data.STATUS !== 'SUCCESS';

    useEffect(() => {
        if(client.id.length > 0) {
            get_clientcontacts_refetch();
        }
    }, [client, get_clientcontacts_refetch])

    useEffect(() => {
        const dateRec = new Date(props.data.date_received);
        const dateDue = new Date(props.data.date_due);
        setName(props.data.workorder_name);
        setWOStatus(WorkorderStatusDropdownItems.find((status: DropdownItem) => status.id === props.data.workorder_status) || {id: '', display: ''});
        setJobType(JobTypeDropdownItems.find((job_type: DropdownItem) => job_type.id === props.data.job_type) || {id: '', display: ''});
        setDateReceived(new Date(
            dateRec.getUTCFullYear(),
            dateRec.getUTCMonth(),
            dateRec.getUTCDate()
        ));
        setDateDue(new Date(
            dateDue.getUTCFullYear(),
            dateDue.getUTCMonth(),
            dateDue.getUTCDate()
        ));
        setEstimateAmount(props.data.estimate_amount);
    }, [props.data]);


    // WORKORDER UPDATING 
    const { 
        data: add_data,
        status: add_status, 
        refetch: add_refetch, 
        remove: add_remove 
    } = useQuery('client_add', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.id,
                    workorder_name: name,
                    workorder_status: woStatus.id,
                    client_id: client.id,
                    client_contact_id: clientContact.id,
                    county_id: countiesChanged ? counties.map(county => county.id) : undefined,
                    job_type: jobType.id,
                    date_received: new Date(dateReceived).toISOString(),
                    date_due: new Date(dateDue).toISOString(),
                    estimate_amount: estimate_amount
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const add_loading = add_status !== 'success' || add_data.STATUS !== 'SUCCESS';

    const setCountyArray = (set_value: any) => {
        setCounties(set_value);
        setNumCounties(numCounties + 1);
        setCountiesChanged(true);
    }

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Name",
            attributeType: MenuAttributeTypes.short_input,
            set_value: name,
            setValue: setName,
        }, {
            name: "Client",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: client,
            setValue: setClient,
            dropdown_values: clientOptions,
            disabled: get_clients_loading
        }, {
            name: "Client Contact",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: clientContact,
            setValue: setClientContact,
            dropdown_values: clientContactOptions,
            disabled: get_clientcontacts_loading || client.id.length === 0
        }, {
            name: "County",
            attributeType: MenuAttributeTypes.dropdown_input,
            link: {
                name: 'Add County',
                route: '/county'
            },
            set_value: counties,
            setValue: setCountyArray,
            dropdown_values: countyOptions || [],
            disabled: countyOptions === null,
            multi: true
        }, {
            name: "Status",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: woStatus,
            setValue: setWOStatus,
            dropdown_values: WorkorderStatusDropdownItems
        }, {
            name: "Job Type",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: jobType,
            setValue: setJobType,
            dropdown_values: JobTypeDropdownItems
        }, {
            name: "Date Received",
            attributeType: MenuAttributeTypes.date_input,
            set_value: dateReceived,
            setValue: setDateReceived,
        }, {
            name: "Date Due",
            attributeType: MenuAttributeTypes.date_input,
            set_value: dateDue,
            setValue: setDateDue,
        }, {
            name: "Estimate",
            attributeType: MenuAttributeTypes.currency_input,
            set_value: estimate_amount,
            setValue: setEstimateAmount
        }
    ];

    const onSubmit = () => {
        add_refetch();
    }

    useEffect(() => {
        if (!add_loading) {
            setName('');
            setWOStatus({
                id: '',
                display: ''
            });
            setClientContact({
                id: '',
                display: ''
            });
            setCounties([{
                id: '',
                display: ''
            }]);
            setNumCounties(0);
            setJobType({
                id: '',
                display: ''
            });
            setDateReceived(new Date());
            setDateDue(new Date());
            setEstimateAmount(Number.NaN);
            add_remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [add_loading, props, add_remove]);

    const [formFilledCorrectly, setFormFilledCorrectly] = React.useState(false);
    useEffect(() => {
        const allCountiesDefined = counties.find(county => county.id.length === 0) === undefined; // when there are no counties with 0 length
        const result = !(name.length === 0 || clientContact.id.length === 0 || !allCountiesDefined ||
        woStatus.id.length === 0 || jobType.id.length === 0 || new Date(dateReceived) > new Date(dateDue));
        console.log(`Form filled correctly: ${result}`);
        console.log(counties);
        setFormFilledCorrectly(result);
    }, [name, clientContact, numCounties, woStatus, jobType, dateReceived, dateDue]);
    
    const isSaveButtonDisabled = (): boolean => !formFilledCorrectly;
    
    return (
        <FormModal 
            title='Update Workorder'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    );
}

export default WorkorderUpdateModal;