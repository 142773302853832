import React from 'react';
import { Text, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Box } from '@chakra-ui/react';
import MenuAttribute, { MenuAttributeProps } from './MenuAttribute';
import { InternalLink } from '../common/components';

interface FormModalProps<T> {
    title: string;
    form_strategy: "Add" | "Update" | "Delete" | "Fetch";
    menuAttributes: MenuAttributeProps[];
    data?: T;
    onClose: () => void;
    isOpen: boolean;
    isSaveButtonDisabled: () => boolean;
    onSave: () => void;
    message?: string;
}

const FormModal = <T, >(props: FormModalProps<T>): JSX.Element => {
    const disableButton = props.isSaveButtonDisabled();

    return (
        <Modal size='lg' isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{ props.title }</ModalHeader>
                <ModalBody>
                    {props.message && <p>{ props.message } <br /></p>}
                    {
                        props.menuAttributes.map((att_props: MenuAttributeProps, idx: number) => 
                            {
                                const intLink = att_props.link
                                    ? <InternalLink content={` (${att_props.link.name})`} route={att_props.link.route} />
                                    : <></>;
                                return (<Box marginBottom='1em' key={idx}>
                                    <Text><b>{ att_props.name } {intLink} </b></Text>
                                    <MenuAttribute 
                                        {...att_props}
                                    />
                                </Box>);
                            }
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme={props.form_strategy === 'Delete' ? 'red' : 'blue'}
                        mr={3}
                        onClick={props.onSave}
                        disabled={disableButton}
                    >
                        {props.form_strategy}
                    </Button>
                    <Button onClick={props.onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default FormModal;