import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import CustomTable, { TableColumn } from '../../table/CustomTable';
import CountyAddModal from './CountyAddModal';
import CountyDeleteModal from './CountyDeleteModal';
import CountyUpdateModal from './CountyUpdateModal';

const columns: TableColumn<County>[] = [
    {
        id: 'name',
        title: 'Name',
        render_strategy: (county: County) => <p>{ county.county_name }</p>
    },
    {
        id: 'state',
        title: 'State',
        render_strategy: (county: County) => <p>{ county.state_code }</p>
    },
];

interface CountyTableProps {
    loading: boolean;
    data: County[];
    refresh: () => void;
}

const CountyTable = (props: CountyTableProps): JSX.Element => {
    const { isOpen: addIsOpen, onOpen: addOnOpen, onClose: addOnClose } = useDisclosure();
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState({id: -1, county_name: '', state_code: ''} as County);

    const onEdit = (data: County) => {
        setSelectedRow(data);
        updateOnOpen();
    }

    const onDelete = (data: County) => {
        setSelectedRow(data);
        deleteOnOpen();
    }

    return (
        <>
            <CustomTable
                tableHeader={'Counties'}
                columns={columns}
                loading={props.loading}
                data={props.data}
                onDelete={onDelete}
                onEdit={onEdit}
                onAdd={addOnOpen}
            />
            <CountyAddModal
                refreshTable={props.refresh}
                isOpen={addIsOpen}
                closeModal={addOnClose}
            />
            <CountyUpdateModal
                refreshTable={props.refresh}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                data={selectedRow}
            />
            <CountyDeleteModal
                refreshTable={props.refresh}
                isOpen={deleteIsOpen}
                closeModal={deleteOnClose}
                data={selectedRow}
            />
        </>
    )
}

export interface County {
    id: number;
    county_name: string;
    state_code: string;
}

export default CountyTable;