import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import WorkorderTable, { Workorder } from './WorkorderTable';

const queryClient = new QueryClient();

interface WorkorderPageAdminProps {}

const WorkorderPageAdmin = (props: WorkorderPageAdminProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderPageAdminInner {...props}/>
    </QueryClientProvider>
);

const WorkorderPageAdminInner = (props: WorkorderPageAdminProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [page, setPage] = React.useState(0);
    const [allData, setAllData] = React.useState([] as Workorder[]);

    const { data, status, refetch } = useQuery('admin_workorders', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorders?access_token=${sessionDetails.access_token}&page=${page}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data.STATUS === 'SUCCESS') {
                setAllData([...allData, ...data.DATA]);
            }
        }
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    const onLoadMore = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        refetch();
    }, [page]);

    const refresh = () => {
        setAllData([]);
        setPage(0);
        refetch();
    }

    return (
        <div>
            <WorkorderTable
                loading={loading}
                data={loading ? [] : allData}
                workorder_route={'workorders'}
                admin_display={true}
                refresh={refresh}
                onLoadMoreButtonPress={onLoadMore}
            />
        </div>
    )
};

export default WorkorderPageAdmin;