import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import WorkorderTable from './WorkorderTable';

const queryClient = new QueryClient();

interface WorkorderPageAdminProps {}

const WorkorderPageAdminArchived = (props: WorkorderPageAdminProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderPageAdminInner {...props}/>
    </QueryClientProvider>
);

const WorkorderPageAdminInner = (props: WorkorderPageAdminProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorders_archived?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    return (
        <div>
            <WorkorderTable
                loading={loading}
                data={loading ? [] : data.DATA}
                workorder_route={'workorders'}
                admin_display={true}
                refresh={refetch}
            />
        </div>
    )
};

export default WorkorderPageAdminArchived;