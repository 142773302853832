import React, { useContext } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Omit } from '@material-ui/types';

import { Link } from 'react-router-dom';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../App';

export enum NavigationTab {
  MyTimesheet = 'My Timesheet',
  MyWorkorders = 'My Workorders',
  AllTimesheets = 'All Timesheets',
  AllWorkorders = 'All Workorders',
  Clients = 'Clients',
  Counties = 'Counties',
  AdminPanel = 'Admin Panel'
};

export interface NavigationCategories {
  id: string;
  admin_only: boolean;
  children: {
    id: string;
    icon: any;
    tabId: NavigationTab;
    route: string;
    active: boolean;
  }[]
}

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontSize: 'inherit',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {
  currentPage: NavigationTab;
  categories: NavigationCategories[];
}

function Navigator(props: NavigatorProps) {
  const { categories, currentPage, classes, ...other } = props;
  const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

  categories.forEach((category) => category.children.forEach((child) => child.active = child.tabId === currentPage));

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Wolcott TPS
        </ListItem>
        {categories
          .filter((category) => !category.admin_only || sessionDetails.permission_level === UserPermissions.admin)
          .map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, route }) => (
              <Link to={route} key={childId}>
                <ListItem
                  button
                  className={clsx(classes.item, active && classes.itemActiveItem)}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);