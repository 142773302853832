import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import TimeEntryTable, { TimeEntryTableLocation } from './TimeEntryTable';

const queryClient = new QueryClient()

interface TimeEntryAdminPageProps {}

const TimeEntryAdminPageSinglePersonnel = (props: TimeEntryAdminPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimeEntryPageInner {...props}/>
    </QueryClientProvider>
);

const TimeEntryPageInner = (props: TimeEntryAdminPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [page, setPage] = React.useState(0);
    const { personnel_id } = useParams<{ personnel_id: string }>();

    const fetchUrl = `${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entries_for_user?access_token=${
        sessionDetails.access_token}&personnel_id=${personnel_id}&page=${page}`;
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(fetchUrl, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    const onLoadMore = () => {
        setPage(page + 1);
    };

    let suffix = "";
    try {
        suffix = ` for ${data.DATA[0]["personnel_name"]}`;
    } catch(e) {

    }

    useEffect(() => {
        refetch();
    }, [page]);
    
    return (
        <div>
            <TimeEntryTable
                refreshTable={refetch}
                loading={loading}
                data={loading ? [] : data.DATA}
                table_location={TimeEntryTableLocation.AllTimesheets}
                show_rates={true}
                userPermissions={UserPermissions.admin}
                onLoadMoreButtonPress={onLoadMore}
                allowTimesheetLocking
                useLockDate={false}
                tableHeaderSuffix={suffix}
            />
        </div>
    )
};

export default TimeEntryAdminPageSinglePersonnel;