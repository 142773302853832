import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import CustomTable, { TableColumn } from '../../table/CustomTable';
import AdminManageUpdateModal from './AdminManageUpdateModal';

const columns: TableColumn<PersonnelAdmin>[] = [
    {
        id: 'full_name',
        title: 'Name',
        render_strategy: (war: PersonnelAdmin) => <p>{ war.full_name }</p>
    },
    {
        id: 'email',
        title: 'Email',
        render_strategy: (war: PersonnelAdmin) => <p>{ war.email }</p>
    },
    {
        id: 'ad_username',
        title: 'AD',
        render_strategy: (war: PersonnelAdmin) => <p>{ war.ad_username }</p>
    },
    {
        id: 'llc',
        title: 'LLC',
        render_strategy: (war: PersonnelAdmin) => <p>{ war.llc }</p>
    },
    {
        id: 'admin',
        title: 'Permissions',
        render_strategy: (war: PersonnelAdmin) => <p> {war.admin ? 'Admin' : 'User'} </p>
    }
]

interface AdminManageTableProps {
    loading: boolean;
    data: PersonnelAdmin[];
    refresh: () => void;
}

const AdminManageTable = (props: AdminManageTableProps): JSX.Element => {
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState({
        id: -1,
        full_name: '',
        email: '',
        ad_username: '',
        admin: false,
        llc: ' '
    } as PersonnelAdmin);

    const onEdit = (data: PersonnelAdmin) => {
        setSelectedRow(data);
        updateOnOpen();
    };

    return (
        <>
            <CustomTable
                tableHeader='Personnel'
                columns={columns}
                loading={props.loading}
                data={props.data}
                onEdit={onEdit}
            />
            <AdminManageUpdateModal
                refreshTable={props.refresh}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                data={selectedRow}
            />
        </>
    ); 
}

export interface PersonnelAdmin {
    id: number;
    full_name: string;
    email: string;
    ad_username: string;
    admin: boolean;
    llc: string;
}

export default AdminManageTable;