import React, { useContext } from 'react';
import { Button, Center, Stack, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TimesheetLockModal from './timesheet_lock_modal/TimesheetLockModal';
import QuickbooksModal from './quickbooks/QuickbooksModal';
import ExportDataModal from './csv_download/ExportDataModal';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../App';
import { readableDate } from '../common/utils';
import LockDateModal from './lock_date/LockDateModal';

interface AdminPageProps {}

const queryClient = new QueryClient();

const AdminPage = (props: AdminPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <AdminPageInner {...props}/>
    </QueryClientProvider>
)

const AdminPageInner = (props: AdminPageProps): JSX.Element => {
    const { isOpen: timesheetModalIsOpen, onOpen: openTimesheetModal, onClose: closeTimesheetModal } = useDisclosure();
    const { isOpen: quickbooksModalIsOpen, onOpen: openQuickbooksModal, onClose: closeQuickbooksModal } = useDisclosure();
    const { isOpen: exportModalIsOpen, onOpen: openExportModal, onClose: closeExportModal } = useDisclosure();
    const { isOpen: lockDateModalIsOpen, onOpen: openLockDateModal, onClose: closeLockDateModal } = useDisclosure();

    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

    const { data, status, refetch } = useQuery('lock_date', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/lock_date?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    const date_str = loading ? '...' : readableDate(new Date(data.DATA));

    const {data: mileageRateData} = useQuery('mileage_rate', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/mileage_rate?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    });

    const mileageRate = (mileageRateData && mileageRateData.STATUS === 'SUCCESS')
        ? mileageRateData.DATA as number
        : 1;

    const buttonProps = {
        margin: '0.5em',
        colorScheme: 'red'
    };

    return (
        <>
            <Stack>
                {/* Manage Admins */}
                <Center>
                    <Link to='/admin_panel/admin_management'>
                        <Button {...buttonProps}>Manage Personnel</Button>
                    </Link>
                </Center>
                {/* Archived Workorders */}
                <Center>
                    <Link to='/admin_panel/archived_workorders'>
                        <Button {...buttonProps}>Archived Workorders</Button>
                    </Link>
                </Center>
                <Center>
                    <Button {...buttonProps} onClick={openExportModal}> Export Data </Button>
                </Center>
                <Center>
                    <Button {...buttonProps} onClick={openTimesheetModal}>Lock Time Entries</Button>
                </Center>
                <Center>
                    <Button {...buttonProps} onClick={openQuickbooksModal}>Quickbooks Imports</Button>
                </Center>
                <Center>
                    <Button {...buttonProps} onClick={openLockDateModal}>Lock Date: {date_str} </Button>
                </Center>
                <Center>
                    <Link to='/admin_panel/analysis'>
                        <Button {...buttonProps}>Analysis</Button>
                    </Link>
                </Center>
            </Stack>
            { timesheetModalIsOpen && <TimesheetLockModal isOpen={timesheetModalIsOpen} closeModal={closeTimesheetModal}/>}
            { quickbooksModalIsOpen && <QuickbooksModal defaultMileageRate={mileageRate} isOpen={quickbooksModalIsOpen} closeModal={closeQuickbooksModal} refreshLockDate={refetch}/>}
            { exportModalIsOpen && <ExportDataModal isOpen={exportModalIsOpen} closeModal={closeExportModal}/> }
            { lockDateModalIsOpen && <LockDateModal isOpen={lockDateModalIsOpen} closeModal={closeLockDateModal} refreshDate={refetch}/> }
        </>
    )
}

export default AdminPage;