import React from 'react';
import qs from 'qs';
import { County } from '../content/county/CountyTable';
import { TimeEntry } from '../content/time_entries/TimeEntryTable';
import { Workorder } from '../content/workorders/WorkorderTable';

export const readableDate = (date: Date): string => {
    const month = (date.getUTCMonth() + 1) + '';
    const day = date.getUTCDate() + '';
    return `${month.length === 1 ? '0' + month : month}-${day.length === 1 ? '0' + day : day}-${date.getUTCFullYear()}`
};

const monthNames: {[key: number]: string} = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
}

export const readableDateEl = (date: Date): JSX.Element => {
    return (
        <>
            <p style={{textAlign: 'center'}}>{monthNames[date.getUTCMonth() + 1]}</p>
            <p style={{textAlign: 'center'}}>{date.getUTCDate()}</p>
            <p style={{textAlign: 'center'}}>{date.getUTCFullYear()}</p>
        </>
    );
};


export const readableCurrency = (value: number): string => {
    let value_string = value + '';
    if(value_string.length === 0 || value === null){
        return '-';
    }
    const dec_index = value_string.indexOf('.');
    if(dec_index === -1) {
        return value_string + '.00';
    }
    value_string = dec_index === 0 ? '0' + value_string : value_string;
    
    while(value_string.length - dec_index - 1 < 2) {
        value_string += '0';
    }
    return value_string;
};

export const lists_to_CSV = (values: any[][]) => {
    return values.map((row: any[]) => row.map(val => {
        if ((typeof val === 'string' || val instanceof String) && (val as string).indexOf(',') !== -1) {
            return `"${val}"`;
        }
        return val;
    }).join(',')).join('\n');
}

export interface MicrosoftAuthResponse {
    id_token: string;
    state: string;
}

export const get_microsoft_auth_response_from_url = (url: string): MicrosoftAuthResponse => {
    const results = qs.parse(url.split('#')[1]);
    return {
        id_token: results['id_token'] as string,
        state: results['state'] as string
    };
}

export const get_workorder_counties_list_readable = (wo: Workorder): string => 
    wo.counties.length === 1
        ? `${ wo.counties[0].county_name }, ${ wo.counties[0].state_code }`
        : wo.counties
            .map((county: County) => {return `${ county.county_name }, ${ county.state_code }`})
            .map(str => `(${str})`)
            .join(' ');
    

/*export const useCookie = (cookie_name: string) => {
    const [cookies, setCookie, removeCookie] = useCookies([cookie_name]);
    return [cookies[cookie_name]
} */

const link_prefix = 'https://';
export const fileLinkDisplay = (timeEntry: TimeEntry): JSX.Element => {
    const links = timeEntry.file_link
                    .split(',')
                    .filter((link: string) => link.length > link_prefix.length && link.substring(0, link_prefix.length) === link_prefix);
    return <>
        { 
            links.map(
                (link: string, i: number) => 
                    <b>(<a href={link}>{i}</a>)</b>
            )
        }
    </>
}