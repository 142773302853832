import React from "react";
import { Link } from "react-router-dom";
import "./components.css";

  
interface InternalLinkProps {
    content: string;
    route: string;
}

export const InternalLink = (props: InternalLinkProps): JSX.Element => (
    <Link className="internal_link" to={props.route}><b>{ props.content }</b></Link>
)
