import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { readableDate } from '../../common/utils';
import FormModal from '../../modal/FormModal';
import { DropdownItem, MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import { Personnel } from '../personnel/admin/PersonnelTableAdmin';
import { Workorder } from '../workorders/WorkorderTable';
import { TimeEntryDayWorkedDropdownOptions } from './TimeEntryAddModal';
import { TaskTypeDropdownItems, TimeEntry, TimeEntryTableLocation } from './TimeEntryTable';

const queryClient = new QueryClient();

interface TimeEntryUpdateModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    table_location: TimeEntryTableLocation;
    include_rates: boolean;
    workorder?: Workorder;
    user_permissions: UserPermissions;
    time_entry: TimeEntry;
    useLockDate: boolean;
}

const TimeEntryUpdateModal = (props: TimeEntryUpdateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimeEntryFormModal {...props} />
    </QueryClientProvider>
);

const TimeEntryFormModal = (props: TimeEntryUpdateModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const [personnel, setPersonnel] = React.useState({id: '', display: ''} as DropdownItem);
    const [personnelOptions, setPersonnelOptions] = React.useState([] as DropdownItem[]);
    const [workorder, setWorkorder] = React.useState({id: '', display: ''} as DropdownItem);
    const [workorderOptions, setWorkorderOptions] = React.useState([] as DropdownItem[]);
    const [task_type, setTaskType] = React.useState({id: '', display: ''} as DropdownItem);
    const [date, setDate] = React.useState(new Date());
    const [work_description, setWorkDescription] = React.useState('');
    const [lodging, setLodging] = React.useState(0);
    const [meals, setMeals] = React.useState(0);
    const [mileage, setMileage] = React.useState(0);
    const [copies_documents, setCopiesDocuments] = React.useState(0);
    const [other_expenses, setOtherExpenses] = React.useState(0);
    const [other_expenses_detail, setOtherExpensesDetail] = React.useState('');
    const [day_worked, setDayWorked] = React.useState({id: '', display: ''} as DropdownItem);
    const [billRate, setBillRate] = React.useState(0);
    const [costRate, setCostRate] = React.useState(0);


    const [message, setMessage] = React.useState('');
    const [validDate, setValidDate] = React.useState(true);

    // DATE LOCK FETCHING
    const { data: lockDateData, status: lockDateStatus } = useQuery('date_lock_fetch', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/lock_date?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
    });
    const loading = lockDateStatus !== 'success' || lockDateData.STATUS !== 'SUCCESS';
    const lockDate = loading ? new Date() : new Date(lockDateData.DATA);
    const lockDateStr = loading ? '' : readableDate(lockDate);
    
    
    // WORKORDER FETCHING
    const { 
        data: get_workorder_data,
        status: get_workorder_status, 
    } = useQuery('get_workorders', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/${props.table_location === TimeEntryTableLocation.AllTimesheets ? 'workorders' : 'workorder'}?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const options = data.DATA.map((workorder: Workorder) => ({
                id: workorder.id + '',
                display: workorder.workorder_name
            } as DropdownItem));
            setWorkorderOptions(options);
            setWorkorder(
                options.find((option: DropdownItem) => option.id === props.time_entry.workorder_id + '') || {id: '', display: ''}
            );
        }
    });
    const get_workorder_loading = get_workorder_status !== 'success' || get_workorder_data.STATUS !== 'SUCCESS';
    

    // PERSONNEL FETCHING
    const { 
        data: get_personnel_data,
        status: get_personnel_status, 
        refetch: get_personnel_refetch
    } = useQuery('get_personnel_workorder', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/personnel_workorder?access_token=${sessionDetails.access_token}&workorder_id=${workorder.id}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (data) => {
            const options: DropdownItem[] = data.DATA.personnel.map((employee: Personnel) => ({
                id: employee.id + '',
                display: employee.full_name
            } as DropdownItem));
            setPersonnelOptions(options);
            setPersonnel(options.find((option: DropdownItem) => option.id === (props.time_entry.personnel_id + '')) || {id: '', display: ''});
        }
    });
    const get_personnel_loading = get_personnel_status !== 'success' || get_personnel_data.STATUS !== 'SUCCESS';


    useEffect(() => {
        if(workorder.id.length > 0) {
            get_personnel_refetch();
        }
    }, [workorder, get_personnel_refetch]);

    useEffect(() => {
        const date = new Date(props.time_entry.date);
        setDate(new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate()
        ));
        setTaskType(TaskTypeDropdownItems.find((item: DropdownItem) => item.id === props.time_entry.task_type) || {id: '', display: ''});
        setWorkDescription(props.time_entry.work_description);
        setLodging(props.time_entry.lodging);
        setMeals(props.time_entry.meals);
        setMileage(props.time_entry.mileage);
        setCopiesDocuments(props.time_entry.copies_documents);
        setOtherExpenses(props.time_entry.other_expenses);
        setOtherExpensesDetail(props.time_entry.other_expenses_detail);
        setDayWorked(TimeEntryDayWorkedDropdownOptions.find((item: DropdownItem) => item.id === props.time_entry.day_worked + '') || {id: '', display: ''});
        setBillRate(props.time_entry.bill_rate);
        setCostRate(props.time_entry.cost_rate);
    }, [props.time_entry]);

    // TIME ENTRY UPDATING 
    const { 
        data: add_data,
        status: add_status, 
        refetch: add_refetch, 
        remove: add_remove 
    } = useQuery('timeentry_update', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entry`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.time_entry.id,
                    personnel_id: personnel.id,
                    workorder_id: workorder.id,
                    task_type: task_type.id,
                    date: date,
                    work_description: work_description,
                    lodging: lodging,
                    meals: meals,
                    mileage: mileage,
                    copies_documents: copies_documents,
                    other_expenses: other_expenses,
                    other_expenses_detail: other_expenses_detail,
                    day_worked: day_worked.id,
                    bill_rate: props.include_rates ? billRate : undefined,
                    cost_rate: props.include_rates ? costRate: undefined,
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const add_loading = add_status !== 'success' || add_data.STATUS !== 'SUCCESS';

    const setDateWrapper = (date: Date) => {
        console.log('here');
        console.log(lockDate);
        console.log(date);
        console.log(props.useLockDate && lockDate >= new Date(date));
        setDate(date);
        if (props.useLockDate && lockDate >= new Date(date)) {
            setValidDate(false);
            setMessage(`Time entry must be for dates after: ${lockDateStr}`);
            return;
        } else {
            setValidDate(true);
            setMessage('');
        }
    }

    const menuAttributesStatic: MenuAttributeProps[] = [
        {
            name: 'Workorder',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: workorder,
            setValue: setWorkorder,
            dropdown_values: workorderOptions,
            disabled: get_workorder_loading || !!props.workorder
        }, {
            name: 'Personnel',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: personnel,
            setValue: setPersonnel,
            dropdown_values: personnelOptions,
            disabled: get_personnel_loading || props.user_permissions === UserPermissions.user || workorder.id.length === 0
        }, {
            name: 'Task Type',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: task_type,
            setValue: setTaskType,
            dropdown_values: TaskTypeDropdownItems
        }, {
            name: 'Date',
            attributeType: MenuAttributeTypes.date_input,
            set_value: date,
            setValue: setDateWrapper,
        }, {
            name: 'Day Worked',
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: day_worked,
            setValue: setDayWorked,
            dropdown_values: TimeEntryDayWorkedDropdownOptions
        }, {
            name: 'Work Description',
            attributeType: MenuAttributeTypes.long_input,
            set_value: work_description,
            setValue: setWorkDescription,
        }, {
            name: 'Lodging',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: lodging,
            setValue: setLodging,
        }, {
            name: 'Meals',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: meals,
            setValue: setMeals,
        }, {
            name: '# Miles',
            attributeType: MenuAttributeTypes.twodec_input,
            set_value: mileage,
            setValue: setMileage,
        }, {
            name: 'Copies/Documents',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: copies_documents,
            setValue: setCopiesDocuments,
        }, {
            name: 'Other Expenses',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: other_expenses,
            setValue: setOtherExpenses,
        }, {
            name: 'Other Expenses Detail',
            attributeType: MenuAttributeTypes.short_input,
            set_value: other_expenses_detail,
            setValue: setOtherExpensesDetail,
        }
    ];

    const menuAttributes: MenuAttributeProps[] = props.include_rates
        ? [...menuAttributesStatic, {
            name: 'Bill Rate',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: billRate,
            setValue: setBillRate
        }, {
            name: 'Cost Rate',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: costRate,
            setValue: setCostRate
        }]
        : menuAttributesStatic;

    const onSubmit = () => {
        add_refetch();
    }

    useEffect(() => {
        if (!add_loading) {
            setMessage('');
            add_remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [add_loading, props, add_remove])

    const isSaveButtonDisabled = (): boolean =>
        !validDate || personnel.id.length === 0 || workorder.id.length === 0 || task_type.id.length === 0 || 
        day_worked.id.length === 0 || work_description.length === 0 || lodging < 0 || Number.isNaN(lodging) || Number.isNaN(meals) || Number.isNaN(mileage) || Number.isNaN(other_expenses) ||
        meals < 0 || mileage < 0 || copies_documents < 0 || other_expenses < 0 || (other_expenses > 0 && other_expenses_detail.length === 0);

    return (
        <FormModal 
            title='Update Time Entry'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
            message={message}
        />
    );
}

export default TimeEntryUpdateModal;