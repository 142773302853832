import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import FormModal from '../../modal/FormModal';
import { County } from './CountyTable';

const queryClient = new QueryClient()

interface CountyDeleteModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: County;
}

const CountyDeleteModal = (props: CountyDeleteModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <CountyDeleteModalInner {...props}/>
    </QueryClientProvider>
);

const CountyDeleteModalInner = (props: CountyDeleteModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    // disabled if there exists a form element w/ length 0
    const isSaveButtonDisabled = (): boolean => false;

    // submit request stuff
    const { data, status, refetch, remove } = useQuery('client_add', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/county`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.id
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Delete County'
            form_strategy='Delete'
            menuAttributes={[]}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default CountyDeleteModal;