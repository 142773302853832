import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import FormModal from '../../modal/FormModal';
import { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import { PersonnelAdmin } from './AdminManageTable';

const queryClient = new QueryClient();

interface AdminManageUpdateModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: PersonnelAdmin;
}

const AdminManageUpdateModal = (props: AdminManageUpdateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <AdminManageUpdateModalInner {...props}/>
    </QueryClientProvider>
)

const AdminManageUpdateModalInner = (props: AdminManageUpdateModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [admin, setAdmin] = React.useState(false);
    const [llc, setLLC] = React.useState(' ');
    useEffect(() => {
        setAdmin(props.data.admin);
        setLLC(props.data.llc);
    }, [props.data]);

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Admin",
            attributeType: MenuAttributeTypes.checkbox_input,
            set_value: admin,
            setValue: setAdmin
        },
        {
            name: "LLC",
            attributeType: MenuAttributeTypes.short_input,
            set_value: llc,
            setValue: setLLC
        }
    ];

    // disabled if not different from current
    const isSaveButtonDisabled = (): boolean =>
        props.data.admin === admin && props.data.llc === llc;

    // submit request stuff
    const { data, status, refetch, remove } = useQuery('rate_update', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/personnel_admin`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.id,
                    admin: admin,
                    llc: llc
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    
    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            setAdmin(false);
            setLLC(' ');
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);

    return (
        <FormModal 
            title='Update Personnel'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
            message={"Please note that this will log out this user if they're currently active."}
        />
    );
}

export default AdminManageUpdateModal;