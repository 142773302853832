import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Center, HStack, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Stack, Switch, Textarea } from "@chakra-ui/react";
import DatePickerInput from "./DatePickerInput";

export enum MenuAttributeTypes {
    short_input = "short_input", // used for names, email, phone #, address, short details
    long_input = "long_input", // used for long descriptions
    dropdown_input = "dropdown_input", // used for selections, state codes
    twodec_input = "twodec_input", // used for currency (number to 2 decimal places) (regex to confirm numbers before 1 period, then 2 numbers OR just numbers)
    currency_input = "currency_input",
    date_input = "date_input", // used for selecting a date
    checkbox_input = "checkbox_input", // used for getting a boolean
    file_upload = "file_upload",
    integer_input = "int_input",
}

const twodec_validation = (input: string): string | null => {
    let dec_count = 0;
    for (const c of input) {
        if (c === '.') {
            dec_count += 1;
            if(dec_count > 1) {
                return null;
            }
        }else if(c < '0' || c > '9') {
            return null;
        }
    } // '1.'  input.length - input.indexOf(.) = 4 - 1 = 3
    if (dec_count === 1) {
        const alt_input = input.indexOf('.') === 0 ? '0' + input : input;
        const places_after_decimal = alt_input.length - alt_input.indexOf('.') - 1;
        if(places_after_decimal === 1){
            return alt_input + '0';
        }else if(places_after_decimal === 2){
            return alt_input;
        }else{
            return null;
        }
    }else {
        return input + '.00';
    }
}

const min_char_code = '0'.charCodeAt(0);
const max_char_code = '9'.charCodeAt(0);

const integer_validation = (input: string): string | null => {
    for(let i = 0; i < input.length; i++) {
        if(input.charCodeAt(i) < min_char_code ||  input.charCodeAt(i) > max_char_code) return null;
    }
    return input;
}

export interface DropdownItem {
    id: string;
    display: string;
}

export interface MenuAttributeTitleLink {
    name: string;
    route: string;
}

export interface MenuAttributeProps {
    name: string;
    link?: MenuAttributeTitleLink;
    attributeType: MenuAttributeTypes;
    setValue: (val: any) => void;
    set_value: any; // initial value
    dropdown_values?: DropdownItem[]; // if dropdown, possible options
    disabled?: boolean;
    multi?: boolean;
}

interface MenuAttributePropsDropdown {
    name: string;
    attributeType: MenuAttributeTypes;
    setValue: (val: any) => void;
    set_value: any; // initial value
    dropdown_values: DropdownItem[]; // if dropdown, possible options
    multi?: boolean;
}

const MenuAttribute = (props: MenuAttributeProps): JSX.Element => {
    const [val, setVal] = useState(Number.isNaN(props.set_value) ? '' : props.set_value + '');
    const [error, setError] = useState(false);
    const [numMulti, setNumMulti] = useState(props.multi && props.set_value ? props.set_value.length : 1);
    

    useEffect(() => {
        if (props.attributeType === MenuAttributeTypes.file_upload && props.multi && props.set_value.length === 0) {
            setNumMulti(1);
            props.setValue([null]);
        }else if(props.multi) {
            setNumMulti(props.set_value.length);
        }
    }, [props.set_value]);

    if (props.attributeType === MenuAttributeTypes.short_input) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => props.setValue(event.target.value);
        return (
            <Input
                value={props.set_value}
                placeholder={props.name}
                onChange={handleChange}
            />
        );
    }else if (props.attributeType === MenuAttributeTypes.dropdown_input && props.multi) {
        const drop_props = props as MenuAttributePropsDropdown;
        const drop_vals = drop_props.dropdown_values || [];
        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
            setError(event.target.value.length === 0);
            const selectedDropdown = drop_props.dropdown_values.find(
                    (item: DropdownItem) => item.id + '' === event.target.value
                ) || {id: '', display: ''};
            const nextVal = props.set_value;
            nextVal[index] = selectedDropdown;
            props.setValue(nextVal);
        };
        const onAddAnotherClick = () => {
            setNumMulti(numMulti + 1);
            const nextVal = props.set_value;
            nextVal.push({id: '', display: ''});
            props.setValue(nextVal);
        };
        const onRemoveOptionClick = (index: number) => {
            const nextVal = props.set_value as DropdownItem[];
            nextVal.splice(index, 1);
            props.setValue(nextVal);
            setNumMulti(numMulti - 1);
        };
        return (
            <>
            {
                Array(numMulti).fill(0).map((v, index: number) => {
                    return (
                        <HStack>
                            <Select
                                key={index}
                                placeholder={`Select ${props.name}`}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleChange(event, index)}
                                disabled={props.disabled}
                                isInvalid={error}
                            >
                                {drop_vals.map((item: DropdownItem, option_index: number) => 
                                    <option key={option_index} value={item.id} selected={props.set_value[index].id === item.id}>{item.display}</option>
                                )}
                            </Select>
                            {
                                index === 0
                                    ? <></>
                                    : <Button onClick={() => onRemoveOptionClick(index)}>X</Button>
                            }
                        </HStack>
                    );
                })
            }
            <Center><Button onClick={onAddAnotherClick}>Add Another County</Button></Center>
            </>
        );
    }else if (props.attributeType === MenuAttributeTypes.dropdown_input) {
        const drop_props = props as MenuAttributePropsDropdown;
        const drop_vals = drop_props.dropdown_values || [];
        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            setError(event.target.value.length === 0);
            const selectedDropdown = drop_props.dropdown_values.find(
                    (item: DropdownItem) => item.id + '' === event.target.value
                ) || {id: '', display: ''};
            props.setValue(selectedDropdown);
        };
        return (
            <Select
                placeholder={`Select ${props.name}`}
                onChange={handleChange}
                disabled={props.disabled}
                isInvalid={error}
            >
                {drop_vals.map((item: DropdownItem, index: number) => 
                    <option key={index} value={item.id} selected={props.set_value.id === item.id}>{item.display}</option>
                )}
            </Select>
        );
    }else if (props.attributeType === MenuAttributeTypes.integer_input) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setVal(event.target.value);
            if (integer_validation(event.target.value)) {
                setError(false);
                props.setValue(parseInt(event.target.value));
            }else{
                setError(true);
                props.setValue(Number.NaN);
            }
        };
        return <Input
            value={val}
            placeholder={props.name}
            onChange={handleChange}
            isInvalid={error}
        />;
    }else if (props.attributeType === MenuAttributeTypes.twodec_input) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setVal(event.target.value);
            if (twodec_validation(event.target.value)) {
                setError(false);
                props.setValue(parseFloat(event.target.value));
            }else{
                setError(true);
                props.setValue(Number.NaN);
            }
        };
        return <Input
            value={val}
            placeholder={props.name}
            onChange={handleChange}
            isInvalid={error}
        />;
    } else if (props.attributeType === MenuAttributeTypes.currency_input) {
        const handleChange = (valueString: string) => {
            const parsedVal = valueString === null ? "" : valueString.replace(/^\$/, "");
            setVal(parsedVal);
            if (twodec_validation(valueString)) {
                setError(false);
                props.setValue(parseFloat(valueString));
            }else{
                setError(true);
                props.setValue(Number.NaN);
            }
        }
        return <NumberInput
            onChange={handleChange}
            value={val === "null" ? "$" : `$` + val}
            precision={2}
            min={0}
            isInvalid={error}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>    
    } else if (props.attributeType === MenuAttributeTypes.date_input) {
        return <DatePickerInput
            set_value={props.set_value}
            setValue={props.setValue}
        />
    } else if (props.attributeType === MenuAttributeTypes.long_input) {
        const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => props.setValue(event.target.value);
        return <Textarea 
            value={props.set_value}
            onChange={handleChange}
            placeholder={props.name}
        />
    } else if (props.attributeType === MenuAttributeTypes.checkbox_input) {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            props.setValue(event.target.checked);
        };
        return <Switch size="md" onChange={handleChange} isChecked={props.set_value}/>
    } else if (props.attributeType === MenuAttributeTypes.file_upload && props.multi) {
        const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
            const nextVal = props.set_value;
            nextVal[index] = event.target.files;
            props.setValue(nextVal);
        };
        const onAddAnotherClick = () => {
            setNumMulti(numMulti + 1);
            const nextVal = props.set_value;
            nextVal.push([]);
            props.setValue(nextVal);
        };
        const onRemoveOptionClick = (index: number) => {
            const nextVal = props.set_value as DropdownItem[];
            nextVal.splice(index, 1);
            props.setValue(nextVal);
            setNumMulti(numMulti - 1);
        };
        return (<>{
            Array(numMulti).fill(0).map((v, index: number) => {
                return (
                    <HStack key={index}>
                        <input
                            type="file"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, index)}
                            name="upload"
                        />
                        {
                            index === 0 || index < numMulti - 1
                                ? <></>
                                : <Button onClick={() => onRemoveOptionClick(index)}>X</Button>
                        }
                    </HStack>
                );
            }) 
        } 
            <Center><Button onClick={onAddAnotherClick}>Add Another File</Button></Center>
        </>);
    } else if (props.attributeType === MenuAttributeTypes.file_upload) {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            props.setValue(event.target.files);
        }
        return (
            <input type="file" onChange={handleChange} name="upload"/>
        );
    }
    return <p>Menu Attribute Error</p>;
}

export default MenuAttribute;