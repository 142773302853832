import FileSaver from 'file-saver';
import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { readableDate } from '../../common/utils';
import FormModal from '../../modal/FormModal';
import MenuAttribute, { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';

const queryClient = new QueryClient();

export interface QuickbooksModalProps {
    isOpen: boolean;
    closeModal: () => void;
    refreshLockDate: () => void;
    defaultMileageRate: number;
}

const QuickbooksModal = (props: QuickbooksModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <QuickbooksModalInner {...props}/>
    </QueryClientProvider>
)
const QuickbooksModalInner = (props: QuickbooksModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

    const [startDate, setStartDate] = React.useState(new Date().toString());
    const [endDate, setEndDate] = React.useState(new Date().toString());
    const [onlyIncludeLockedEntries, setOnlyIncludeLockedEntries] = React.useState(true);
    const [lockTimeEntriesAfterExport, setLockTimeEntriesAfterExport] = React.useState(false);
    const [startingInvoiceNumber, setStartingInvoiceNumber] = React.useState(1);
    const [mileageRate, setMileageRate] = React.useState(props.defaultMileageRate);

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Start Date",
            attributeType: MenuAttributeTypes.date_input,
            set_value: startDate,
            setValue: setStartDate,
        }, {
            name: "End Date",
            attributeType: MenuAttributeTypes.date_input,
            set_value: endDate,
            setValue: setEndDate,
        }, {
            name: 'Only Include Locked Entries',
            attributeType: MenuAttributeTypes.checkbox_input,
            set_value: onlyIncludeLockedEntries,
            setValue: setOnlyIncludeLockedEntries
        }, {
            name: 'Lock Time Entries After Export',
            attributeType: MenuAttributeTypes.checkbox_input,
            set_value: lockTimeEntriesAfterExport,
            setValue: setLockTimeEntriesAfterExport
        }, {
            name: "Starting Invoice Number",
            attributeType: MenuAttributeTypes.integer_input,
            set_value: startingInvoiceNumber,
            setValue: setStartingInvoiceNumber
        }, {
            name: 'Mileage Rate',
            attributeType: MenuAttributeTypes.currency_input,
            set_value: mileageRate,
            setValue: setMileageRate
        }
    ];

    const { data, status, refetch, remove } = useQuery('quickbooks', async () => {
        
        const url_suffix = `&startDate=${startDate}&endDate=${endDate}&onlyIncludeLockedEntries=${onlyIncludeLockedEntries}&lockTimeEntriesAfterExport=${lockTimeEntriesAfterExport}&startingInvoiceNumber=${startingInvoiceNumber}&mileageRate=${mileageRate}`;
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/quickbooks_import_download?access_token=${sessionDetails.access_token}${url_suffix}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    startDate,
                    endDate, 
                    onlyIncludeLockedEntries,
                    lockTimeEntriesAfterExport,
                    startingInvoiceNumber,
                    mileageRate
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    onSuccess: (data) => {
        const date_str = `from_${readableDate(new Date(startDate))}_to_${readableDate(new Date(endDate))}`;
        if (data.STATUS === 'SUCCESS') {
            if (lockTimeEntriesAfterExport) {
                props.refreshLockDate();
            }
            const file_content = data.DATA.split('~~~!!UNIQUE!!~~~') as string[];
            const internal_file = file_content[0];
            const client_file = file_content[1];
            const csv_blob_internal = new Blob([internal_file], { type: 'text/csv;charset=utf-8 ;'});
            const csv_blob_client = new Blob([client_file], { type: 'text/csv;charset=utf-8 ;'});
            FileSaver.saveAs(csv_blob_internal, `quickbooks_import_invoice_${date_str}.iif`);
            FileSaver.saveAs(csv_blob_client, `quickbooks_import_bill_${date_str}.iif`);
        }
    },
    onError: (e) => console.log(e),
});
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const isSaveButtonDisabled = (): boolean => Number.isNaN(startingInvoiceNumber) || Number.isNaN(mileageRate) || startingInvoiceNumber < 0 || mileageRate < 0;

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            setStartDate(new Date().toString());
            setEndDate(new Date().toString());
            setLockTimeEntriesAfterExport(false);
            setOnlyIncludeLockedEntries(true);
            setStartingInvoiceNumber(1);
            setMileageRate(1);
            remove();
            props.closeModal();
        }
    }, [loading, props, remove]);

    return (
        <FormModal 
            title='Get Quickbooks Import Files'
            form_strategy='Fetch'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    );
}

export default QuickbooksModal;