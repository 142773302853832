import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../../App';
import FormModal from '../../../modal/FormModal';
import { DropdownItem, MenuAttributeProps, MenuAttributeTypes } from '../../../modal/MenuAttribute';
import { Personnel, WorkorderAssignmentWithRate } from './PersonnelTableAdmin';

const queryClient = new QueryClient();
const queryClient2 = new QueryClient();

interface PersonnelAddModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    workorder_id: string;
    already_added_wars: WorkorderAssignmentWithRate[];
}

const PersonnelAddModal = (props: PersonnelAddModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <PersonnelSearchWrapper {...props}/>
    </QueryClientProvider>
);

const PersonnelSearchWrapper = (props: PersonnelAddModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { data, status } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/personnel?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    if (loading) {
        return <> </>;
    }

    return (
        <QueryClientProvider client={queryClient2}>
            <PersonnelAddModalInner {...props} employees={data.DATA.personnel} sessionDetails={sessionDetails}/>
        </QueryClientProvider>
    )
}

interface PersonnelAddModalInnerProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    workorder_id: string;
    employees: Personnel[];
    already_added_wars: WorkorderAssignmentWithRate[];
    sessionDetails: UserSessionDetails;
}


const PersonnelAddModalInner = (props: PersonnelAddModalInnerProps): JSX.Element => {
    const [employee, setEmployee] = React.useState({
        id: '',
        display: '',
    } as DropdownItem);
    const [cost_rate, setCostRate] = React.useState(Number.NaN);
    const [bill_rate, setBillRate] = React.useState(Number.NaN);
    
    const employee_exist_map: {[key: string]: boolean;} = {};
    props.employees.forEach((employee: Personnel) => employee_exist_map[employee.id] = false);
    props.already_added_wars.forEach((war: WorkorderAssignmentWithRate) => employee_exist_map[war.personnel_id] = true);

    const dropdown_options = props.employees
        .filter((employee: Personnel) => !employee_exist_map[employee.id])
        .map((employee: Personnel) => ({
            id: employee.id + '',
            display: employee.full_name
        } as DropdownItem));

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Personnel",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: employee,
            setValue: setEmployee,
            dropdown_values: dropdown_options
        },
        {
            name: "Cost Rate",
            attributeType: MenuAttributeTypes.currency_input,
            set_value: cost_rate,
            setValue: setCostRate
        },
        {
            name: "Bill Rate",
            attributeType: MenuAttributeTypes.currency_input,
            set_value: bill_rate,
            setValue: setBillRate
        }
    ];

    // disabled if there exists a form element w/ length 0
    const isSaveButtonDisabled = (): boolean =>
        employee.id.length === 0 || Number.isNaN(cost_rate) || Number.isNaN(bill_rate);
    
    // submit request stuff
    const { data, status, refetch, remove } = useQuery('client_add', async () => {
        const res = await fetch(`${props.sessionDetails.backend.host}:${props.sessionDetails.backend.port}/workorder_assignment_with_rate`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: props.sessionDetails.access_token,
                req: {
                    workorder_id: props.workorder_id,
                    personnel_id: employee.id,
                    cost_rate: cost_rate,
                    bill_rate: bill_rate,
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    const onSubmit = () => {
        refetch();
    }

    useEffect(() => {
        if (!loading) {
            setEmployee({
                id: '',
                display: ''
            });
            setBillRate(Number.NaN);
            setCostRate(Number.NaN);
            remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [loading, props, remove]);
    
    return (
        <FormModal 
            title='Add Personnel & Rate'
            form_strategy='Add'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    )
};

export default PersonnelAddModal;