import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { InternalLink } from '../../common/components';
import CustomTable, { TableColumn } from '../../table/CustomTable';
import ClientAddModal from './ClientAddModal';
import ClientUpdateModal from './ClientUpdateModal';
import ClientDeleteModal from './ClientDeleteModal';

const columns: TableColumn<Client>[] = [
    {
        id: 'name',
        title: 'Name',
        render_strategy: (client: Client) => <p>{ client.name }</p>
    },
    {
        id: 'contacts',
        title: 'Contacts',
        render_strategy: (client: Client) => <p><InternalLink content={`${client.num_contacts} Contacts`} route={`/client_contacts/${client.id}`}/></p>
    }
]

interface ClientTableProps {
    loading: boolean;
    data: Client[];
    refresh: () => void;
    redirectTo: (location: string) => void;
}

const ClientTable = (props: ClientTableProps): JSX.Element => {
    const { isOpen: addIsOpen, onOpen: addOnOpen, onClose: addOnClose } = useDisclosure();
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState({id: -1, name: 'pls'});
    
    const onEdit = (data: Client) => {
        setSelectedRow(data);
        updateOnOpen();
    }

    const onDelete = (data: Client) => {
        setSelectedRow(data);
        deleteOnOpen();
    }

    return (
        <>
            <CustomTable
                tableHeader={'Clients'}
                columns={columns}
                loading={props.loading}
                data={props.data}
                onDelete={onDelete}
                onEdit={onEdit}
                onAdd={addOnOpen}
            />
            <ClientAddModal
                refreshTable={props.refresh}
                isOpen={addIsOpen}
                closeModal={addOnClose}
                redirectTo={props.redirectTo}
            />
            <ClientUpdateModal
                refreshTable={props.refresh}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                data={selectedRow}
            />
            <ClientDeleteModal
                refreshTable={props.refresh}
                isOpen={deleteIsOpen}
                closeModal={deleteOnClose}
                data={selectedRow}
            />
        </>
    );
}

export interface Client {
    id: number;
    name: string;
    num_contacts?: number;
}

export default ClientTable;