import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDisclosure } from '@chakra-ui/react';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { InternalLink } from '../../common/components';
import { fileLinkDisplay, readableCurrency, readableDateEl } from '../../common/utils';
import { DropdownItem } from '../../modal/MenuAttribute';
import CustomTable, { TableColumn } from '../../table/CustomTable';
import { Workorder } from '../workorders/WorkorderTable';
import TimeEntryAddModal from './TimeEntryAddModal';
import TimeEntryDeleteModal from './TimeEntryDeleteModal';
import TimeEntryUpdateModal from './TimeEntryUpdateModal';
import TimeEntryUploadFilesModal from './TimeEntryUploadFilesModal';
import "./TimeEntryTable.css";

export enum TimeEntryTableLocation {
    MyTimesheet,
    AllTimesheets,
    WorkorderPage
}

const locationToRoute: { [key in TimeEntryTableLocation]: string; } = {
    [TimeEntryTableLocation.MyTimesheet]: 'my_workorders',
    [TimeEntryTableLocation.AllTimesheets]: 'workorders',
    [TimeEntryTableLocation.WorkorderPage]: 'err'
};

export enum TaskType {
    abstract='abstract', 
    admin='administrative', 
    due_diligence='due_diligence', 
    geo_research='geospatial_research',
    leasing='leasing',
    management='management',
    title='title',
    other='other'
}

export const TaskTypeDropdownItems: DropdownItem[] = [
    {id: TaskType.abstract, display: 'Abstract'},
    {id: TaskType.admin, display: 'Admin'},
    {id: TaskType.due_diligence, display: 'Due Diligence'},
    {id: TaskType.geo_research, display: 'Geo Research'},
    {id: TaskType.leasing, display: 'Leasing'},
    {id: TaskType.management, display: 'Management'},
    {id: TaskType.title, display: 'Title'},
    {id: TaskType.other, display: 'Other'}
];

export const getReadableTaskType= (taskType: string) => TaskTypeDropdownItems.find((item: DropdownItem) => item.id === taskType)?.display || taskType;

const columns = (table_location: TimeEntryTableLocation, show_rates: boolean, includePersonnelink: boolean): TableColumn<TimeEntry>[] => {
    return [
        {
            id: 'personnel',
            title: 'Personnel',
            render_strategy: (timeEntry: TimeEntry) => includePersonnelink 
                ? <p><InternalLink content={ timeEntry.personnel_name } route={`/personnel/${timeEntry.personnel_id}`}/></p>
                : <p>{timeEntry.personnel_name}</p>
        },
        table_location !== TimeEntryTableLocation.WorkorderPage ? {
            id: 'workorder',
            title: 'Workorder',
            render_strategy: (timeEntry: TimeEntry) => <p><InternalLink content={ timeEntry.workorder_name } route={`/${locationToRoute[table_location]}/${timeEntry.workorder_id}`}/></p>
        } : null,
        {
            id: 'date',
            title: 'Date',
            render_strategy: (timeEntry: TimeEntry) => readableDateEl(new Date(timeEntry.date)),
            center: true
        },
        {
            id: 'day_worked',
            title: 'Day Worked',
            render_strategy: (timeEntry: TimeEntry) => <p>{ `${(timeEntry.day_worked * 100).toFixed(2)}%` }</p>
        },
        {
            id: 'task_type',
            title: 'Task Type',
            render_strategy: (timeEntry: TimeEntry) => <p>{ getReadableTaskType(timeEntry.task_type) }</p>
        },
        {
            id: 'work_desc',
            title: 'Work Description',
            render_strategy: (timeEntry: TimeEntry) => <p className='centeredText'>{ timeEntry.work_description }</p>
        },
        {
            id: 'lodging',
            title: 'Lodging',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.lodging) }</p>
        },
        {
            id: 'meals',
            title: 'Meals',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.meals) }</p>
        },
        {
            id: 'mileage',
            title: 'Miles',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.mileage) }</p>
        },
        {
            id: 'copies_documents',
            title: 'Copies/ Documents',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.copies_documents) }</p>
        },
        {
            id: 'other_expenses',
            title: 'Other Expenses',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.other_expenses) }</p>
        },
        {
            id: 'other_expenses_detail',
            title: 'Details',
            render_strategy: (timeEntry: TimeEntry) => <p>{ timeEntry.other_expenses_detail }</p>
        },
        show_rates ? {
            id: 'bill_rate',
            title: 'Bill Rate',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.bill_rate) }</p>
        } : null,
        show_rates ? {
            id: 'cost_rate',
            title: 'Cost Rate',
            render_strategy: (timeEntry: TimeEntry) => <p>{ readableCurrency(timeEntry.cost_rate) }</p>
        } : null,
        {
            id: 'file_link',
            title: 'Files',
            render_strategy: fileLinkDisplay
        }
    ].filter((col: TableColumn<TimeEntry> | null) => col !== null) as TableColumn<TimeEntry>[];
};

interface TimeEntryProps {
    refreshTable: () => void;
    loading: boolean;
    data: TimeEntry[];
    table_location: TimeEntryTableLocation;
    show_rates: boolean;
    workorder?: Workorder;
    userPermissions: UserPermissions;
    onLoadMoreButtonPress?: () => void;
    onExportButtonPress?: () => void;
    allowTimesheetLocking?: boolean;
    useLockDate: boolean;
    tableHeaderSuffix?: string;
}

const BLANK_ROW = {
    id: -1,
    locked: true,
    personnel_id: -1,
    personnel_name: '',
    workorder_id: -1,
    workorder_name: '',
    task_type: TaskType.other,
    date: new Date(''),
    work_description: '',
    lodging: -1,
    meals: -1,
    mileage: -1,
    other_expenses: -1,
    other_expenses_detail: '',
    day_worked: -1,
    file_link: '',
} as TimeEntry;

const TimeEntryTable = (props: TimeEntryProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

    const { isOpen: addIsOpen, onOpen: addOnOpen, onClose: addOnClose } = useDisclosure();
    const { isOpen: updateIsOpen, onOpen: updateOnOpen, onClose: updateOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const { isOpen: fileUploadIsOpen, onOpen: openFileUpload, onClose: closeFileUpload } = useDisclosure();
    const [ selectedRow, setSelectedRow ] = React.useState(BLANK_ROW);
    const [ needsToLockToggle, setNeedsToLockToggle ] = React.useState(false);

    // LOCK/UNLOCK
    const { refetch: sendLockToggleRequest } = useQuery('timeentry_lock_toggle', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entry_lock`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: selectedRow.id,
                    locked_value: !selectedRow.locked
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
        onSuccess: (data) => {
            props.refreshTable();
        }
    });

    const onAdd = () => {
        setSelectedRow(BLANK_ROW);
        addOnOpen();
    }

    const onDuplicate = (data: TimeEntry) => {
        setSelectedRow(data);
        addOnOpen();
    }

    const onEdit = (data: TimeEntry) => {
        setSelectedRow(data);
        updateOnOpen();
    };

    const onUploadFiles = (data: TimeEntry) => {
        setSelectedRow(data);
        openFileUpload();
    }

    const onDelete = (data: TimeEntry) => {
        setSelectedRow(data);
        deleteOnOpen();
    };

    const onLock = (data: TimeEntry) => {
        setSelectedRow(data);
        setNeedsToLockToggle(true);
    }

    useEffect(() => {
        if (needsToLockToggle) {
            setNeedsToLockToggle(false);
            sendLockToggleRequest();
        }
    }, [needsToLockToggle, sendLockToggleRequest]);

    return (
        <>
            <CustomTable
                tableHeader={`Time Entries${props.tableHeaderSuffix || ""}`}
                columns={columns(props.table_location, props.show_rates, props.userPermissions === UserPermissions.admin)}
                loading={props.loading}
                data={props.data}
                onDelete={onDelete}
                onEdit={onEdit}
                onAdd={onAdd}
                onUpload={onUploadFiles}
                onExport={props.onExportButtonPress}
                onLockToggle={props.allowTimesheetLocking ? onLock : undefined}
                onDuplicate={onDuplicate}
                isRowLocked={(data: TimeEntry) => data.locked}
                disableRow={(data: TimeEntry) => data.locked}
                onLoadMore={props.onLoadMoreButtonPress}
            />
            {addIsOpen && <TimeEntryAddModal
                refreshTable={props.refreshTable}
                isOpen={addIsOpen}
                closeModal={addOnClose}
                table_location={props.table_location}
                user_permissions={props.userPermissions}
                workorder={props.workorder}
                duplicate_data={selectedRow}
                useLockDate={props.useLockDate}
            />}
            {updateIsOpen && <TimeEntryUpdateModal
                refreshTable={props.refreshTable}
                isOpen={updateIsOpen}
                closeModal={updateOnClose}
                table_location={props.table_location}
                include_rates={props.show_rates}
                user_permissions={props.userPermissions}
                workorder={props.workorder}
                time_entry={selectedRow}
                useLockDate={props.useLockDate}
            />}
            {deleteIsOpen && <TimeEntryDeleteModal
                refreshTable={props.refreshTable}
                isOpen={deleteIsOpen}
                closeModal={deleteOnClose}
                data={selectedRow}
            />}
            {fileUploadIsOpen && <TimeEntryUploadFilesModal
                refreshTable={props.refreshTable}
                isOpen={fileUploadIsOpen}
                closeModal={closeFileUpload}
                data={selectedRow}
            />}
        </>
    )
}

export interface TimeEntry {
    id: number;
    locked: boolean;
    personnel_id: number;
    personnel_name: string;
    workorder_id: number;
    workorder_name: string;
    task_type: TaskType;
    date: Date;
    work_description: string;
    lodging: number;
    meals: number;
    mileage: number;
    copies_documents: number;
    other_expenses: number;
    other_expenses_detail: string;
    day_worked: number;
    bill_rate: number;
    cost_rate: number;
    file_link: string;
}

export default TimeEntryTable;