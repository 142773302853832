import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import "./WorkorderPage.css";
import TimeEntryWorkorderDisplay from '../time_entries/TimeEntryWorkorderDisplay';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { WorkorderDetailUser } from './WorkorderDetail';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { Workorder } from '../workorders/WorkorderTable';
import WorkorderDetailDisplayUser from './WorkorderDetailUser';

const queryClient = new QueryClient();

interface WorkorderPageProps {}

const WorkorderDetailPageUser = (props: WorkorderPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderPageInner {...props}/>
    </QueryClientProvider>
);

const WorkorderPageInner = (props: WorkorderPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { workorder_id } = useParams<{ workorder_id: string }>();
    
    const { data: dataRaw, status } = useQuery('workorder_detail_user', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder_detail_user?access_token=${sessionDetails.access_token}&workorder_id=${workorder_id}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || dataRaw.STATUS !== 'SUCCESS';
    
    if (loading) {
        return <div />;
    }

    const data = dataRaw.DATA as WorkorderDetailUser;

    return (
        <Stack
            h="100%"
            gap={'1em'}
        >
            <WorkorderDetailDisplayUser
                    workorder={data}
                />
            {/* Time Entries */}
            <TimeEntryWorkorderDisplay
                workorder_id={workorder_id}
                workorder={data as Workorder}
                userPermissions={UserPermissions.user}
                show_rates={false}
                limit_to_request_user={true}
            />
        </Stack>
    )
}

export default WorkorderDetailPageUser;