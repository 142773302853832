import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import FileSaver from 'file-saver';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { lists_to_CSV } from '../../common/utils';
import TimeEntryTable, { TimeEntry, TimeEntryTableLocation } from './TimeEntryTable';

const queryClient = new QueryClient()

interface TimeEntryUserPageProps {}

const TimeEntryCSVHeadersAdmin = ['Personnel',  'Workorder Name', 'Task Type', 'Date', 'Work Description', 
'Lodging', 'Meals', 'Mileage', 'Copies & Documents' , 'Other Expenses', 'Other Expenses Detail', 'Day Worked',
'Bill Rate', 'Bill Total', 'Cost Rate',  'Cost Total'];
const TimeEntryCSVHeadersUsers = ['Personnel',  'Workorder Name', 'Task Type', 'Date', 'Work Description', 
'Lodging', 'Meals', 'Mileage', 'Copies & Documents' , 'Other Expenses', 'Other Expenses Detail', 'Day Worked'];

const TimeEntryToCSVRow = (entry: TimeEntry, admin: boolean) => admin 
    ? [entry.personnel_name, entry.workorder_name, entry.task_type, entry.date, entry.work_description,
            entry.lodging, entry.meals, entry.mileage, entry.copies_documents, entry.other_expenses, entry.other_expenses_detail, 
            entry.day_worked, entry.bill_rate, entry.bill_rate * entry.day_worked, entry.cost_rate, entry.cost_rate * entry.day_worked]
    : [entry.personnel_name, entry.workorder_name, entry.task_type, entry.date, entry.work_description,
        entry.lodging, entry.meals, entry.mileage, entry.copies_documents, entry.other_expenses, entry.other_expenses_detail, 
        entry.day_worked];
        

export const getCSVForTimeEntries = (entries: TimeEntry[], admin: boolean): string => {
    const lists = [admin ? TimeEntryCSVHeadersAdmin : TimeEntryCSVHeadersUsers, ...entries.map(entry => TimeEntryToCSVRow(entry, admin))];
    return lists_to_CSV(lists);
}

const TimeEntryUserPage = (props: TimeEntryUserPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimeEntryPageInner {...props}/>
    </QueryClientProvider>
);

const TimeEntryPageInner = (props: TimeEntryUserPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const [page, setPage] = React.useState(0);
    const { data, status, refetch } = useQuery('time_entries_for_user', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entries_user?access_token=${sessionDetails.access_token}&page=${page}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const { refetch: export_refetch } = useQuery('time_entries_for_user_download', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entries_user?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    }, {// options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
        onSuccess: (data: any) => {
            if(data.STATUS === 'SUCCESS'){
                const time_entries = data.DATA as TimeEntry[];
                const csv_content = getCSVForTimeEntries(time_entries, false);
                const csv_blob = new Blob([csv_content], { type: 'text/csv;charset=utf-8 ;'});
                FileSaver.saveAs(csv_blob, 'my_timesheet.csv');
            }
        }
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    const onLoadMore = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        refetch();
    }, [page]);
    
    return (
        <div>
            <TimeEntryTable
                refreshTable={refetch}
                loading={loading}
                data={loading ? [] : data.DATA}
                table_location={TimeEntryTableLocation.MyTimesheet}
                show_rates={false}
                userPermissions={UserPermissions.user}
                onLoadMoreButtonPress={onLoadMore}
                onExportButtonPress={export_refetch}
                useLockDate={true}
            />
        </div>
    )
};

export default TimeEntryUserPage;