import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import "./WorkorderPage.css";
import TimeEntryWorkorderDisplay from '../time_entries/TimeEntryWorkorderDisplay';
import PersonnelDisplayAdmin from '../personnel/admin/PersonnelDisplayAdmin';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import WorkorderDetailDisplay, { WorkorderDetail } from './WorkorderDetail';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import CommonPaper from '../../common/CommonPaper';

const queryClient = new QueryClient()

interface WorkorderPageProps {}

const WorkorderDetailPageAdmin = (props: WorkorderPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderPageInner {...props}/>
    </QueryClientProvider>
);

const WorkorderPageInner = (props: WorkorderPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { workorder_id } = useParams<{ workorder_id: string }>();
    
    const { data: dataRaw, status } = useQuery('workorder_detail_admin', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder_detail?access_token=${sessionDetails.access_token}&workorder_id=${workorder_id}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || dataRaw.STATUS !== 'SUCCESS';
    
    if (loading) {
        return <div />;
    }

    const data = dataRaw.DATA as WorkorderDetail;

    return (
        <>
            <CommonPaper content={<WorkorderDetailDisplay workorder={data} />} />
            <CommonPaper content={<PersonnelDisplayAdmin workorder_id={workorder_id} />} />
            <CommonPaper content={
                <TimeEntryWorkorderDisplay
                    workorder_id={workorder_id}
                    workorder={data}
                    userPermissions={UserPermissions.admin}
                    show_rates={true}
                    limit_to_request_user={false}
                />
            }/>
        </>
    );
}

export default WorkorderDetailPageAdmin;