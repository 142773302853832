import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useParams } from "react-router-dom";
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import ClientContactTable from './ClientContactTable';

const queryClient = new QueryClient()

interface ClientContactPageProps {}

const ClientContactPage = (props: ClientContactPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <ClientContactPageInner {...props}/>
    </QueryClientProvider>
);

const ClientContactPageInner = (props: ClientContactPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { client_id } = useParams<{ client_id: string }>();
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/client_contact?access_token=${sessionDetails.access_token}&client_id=${client_id}&include_client_name=true`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    return (
        <div>
            <ClientContactTable
                loading={loading}
                data={loading ? [] : data.DATA.client_contacts}
                client_name={loading ? '...' : data.DATA.client_name}
                client_id={client_id}
                refresh={refetch}
            />
        </div>
    );
};

export default ClientContactPage;