import { Center } from '@chakra-ui/react';
import React, { useState } from 'react';
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";


interface DatePickerInputProps {
    set_value: any;
    setValue: (dateString: string) => void;
}

const DatePickerInput = (props: DatePickerInputProps): JSX.Element => {
    const [date, setDate] = useState(new Date(props.set_value));
    return (
        <Center>
            <DayPicker
                selectedDays={date}
                onDayClick={(date: Date) => {
                    setDate(date);
                    props.setValue(date.toString());
                }} 
            />
        </Center>
    );
}
export default DatePickerInput;