import { Center, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import CommonPaper from '../../common/CommonPaper';
import { get_workorder_counties_list_readable, readableCurrency, readableDate } from '../../common/utils';
import { County } from '../county/CountyTable';
import { getReadableJobType, getReadableWorkorderStatus, JobType, WorkorderStatus } from '../workorders/WorkorderTable';
import "./WorkorderPage.css";

interface WorkorderDetailProps {
    workorder: WorkorderDetail;
}

const WorkorderDetailDisplay = (props: WorkorderDetailProps): JSX.Element => {
    const data = props.workorder;
    
    const gridItemProps = {
        margin: '1em',
    };

    return (
        <>
            <Center>
                <Heading size="lg">
                    {data.workorder_name} for {data.client_name}
                </Heading>
            </Center>
            <Grid
                templateRows="repeat(3, 1fr)"
                templateColumns="repeat(2, 1fr)"
                gap={4}
            >
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Location"
                        additional_lines={data.counties.map(county => `${ county.county_name }, ${ county.state_code }`)}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Contact"
                        body={data.client_contact_name}
                        additional_lines={[
                            data.client_contact_phone,
                            data.client_contact_address,
                            data.client_contact_email
                        ]}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Status"
                        body={getReadableWorkorderStatus(data.workorder_status as string)}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Job Type"
                        body={getReadableJobType(data.job_type as string)}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Date Received"
                        body={readableDate(new Date(data.date_received))}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Date Due"
                        body={readableDate(new Date(data.date_due))}
                    />
                </GridItem>
                <GridItem {...gridItemProps}>
                    <DetailDisplay
                        title="Estimate"
                        body={data.estimate_amount ? readableCurrency(data.estimate_amount) : ""}
                    />
                </GridItem>
            </Grid>
        </>
    );
};

interface DetailDisplayProps {
    title: string;
    body?: string;
    additional_lines?: string[];
}
export const DetailDisplay = (props: DetailDisplayProps): JSX.Element => {
    return (
        <CommonPaper elevation={3} content={
            <Center h="100%">
                <Stack>
                    <Center>
                        <Heading size="md">
                            { props.title }
                        </Heading>
                    </Center>
                    <Center>
                        <div className="details">
                            {props.body && <p>{ props.body }</p>}
                            { props.additional_lines && props.additional_lines.map((line: string) => <p>{ line }</p>) }
                        </div>
                    </Center>
                </Stack>
            </Center>
        } />
    );
}

export interface WorkorderDetail {
    id: number;
    workorder_name: string;
    workorder_status: WorkorderStatus;
    client_id: number;
    client_name: string;
    client_contact_id: number;
    client_contact_name: string;
    client_contact_phone: string;
    client_contact_address: string;
    client_contact_email: string;
    counties: County[];
    job_type: JobType;
    date_received: Date;
    date_due: Date;
    archived: boolean;
    estimate_amount?: number;
}

export interface WorkorderDetailUser {
    workorder_name: string;
    workorder_status: WorkorderStatus;
    client_id: number;
    client_name: string;
    counties: County[];
    job_type: JobType;
    date_received: Date;
    date_due: Date;
    archived: boolean;
}

export default WorkorderDetailDisplay;
