import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserPermissions, UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { Workorder } from '../workorders/WorkorderTable';
import TimeEntryTable, { TimeEntryTableLocation } from './TimeEntryTable';

const queryClient = new QueryClient()

interface TimeEntryWorkorderDisplayProps {
    workorder_id: string;
    workorder: Workorder;
    userPermissions: UserPermissions;
    show_rates: boolean;
    limit_to_request_user: boolean;
}

const TimeEntryWorkorderDisplay = (props: TimeEntryWorkorderDisplayProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimeEntryWorkorderDisplayInner {...props}/>
    </QueryClientProvider>
);

const TimeEntryWorkorderDisplayInner = (props: TimeEntryWorkorderDisplayProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

    const [page, setPage] = React.useState(0);
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entries_workorder${props.limit_to_request_user ? '_user' : ''}?access_token=${sessionDetails.access_token}&workorder_id=${props.workorder_id}&page=${page}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    
    const onLoadMore = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        refetch();
    }, [page]);
    
    return (
        <div>
            <TimeEntryTable
                refreshTable={refetch}
                loading={loading}
                data={loading ? [] : data.DATA}
                table_location={TimeEntryTableLocation.WorkorderPage}
                show_rates={props.show_rates}
                workorder={props.workorder}
                userPermissions={props.userPermissions}
                onLoadMoreButtonPress={onLoadMore}
                allowTimesheetLocking={props.userPermissions === UserPermissions.admin}
                useLockDate={props.userPermissions === UserPermissions.user}
            />
        </div>
    );
};

export default TimeEntryWorkorderDisplay;