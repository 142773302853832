import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import WorkorderTable from './WorkorderTable';

const queryClient = new QueryClient();

interface WorkorderPageUserProps {}

const WorkorderPageUser = (props: WorkorderPageUserProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <WorkorderPageUserInner {...props}/>
    </QueryClientProvider>
);

const WorkorderPageUserInner = (props: WorkorderPageUserProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    
    const { data, status, refetch } = useQuery('workorder_page_user', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorder?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';

    return (
        <div>
            <WorkorderTable
                loading={loading}
                data={loading ? [] : data.DATA}
                workorder_route={'my_workorders'}
                admin_display={false}
                refresh={refetch}
            />
        </div>
    )
};

export default WorkorderPageUser;