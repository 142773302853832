import JSZip, { file } from 'jszip';
import React, { useContext, useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import FormModal from '../../modal/FormModal';
import { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';
import { TimeEntry } from './TimeEntryTable';

const queryClient = new QueryClient();

interface TimeEntryUploadFilesModalProps {
    refreshTable: () => void;
    isOpen: boolean;
    closeModal: () => void;
    data: TimeEntry;
}

const TimeEntryDeleteModal = (props: TimeEntryUploadFilesModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <TimeEntryUploadFilesModal {...props}/>
    </QueryClientProvider>
);

const isFileListValid = (file_lists: FileList[]) => {
    if (file_lists.length === 0) return false;
    for(let i = 0; i < file_lists.length; i++) {
        const file_list = file_lists[i];
        if(!file_list) return false;
        if(file_list.length === 0) return false;
    }
    return true;
}


const TimeEntryUploadFilesModal = (props: TimeEntryUploadFilesModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [file, setFileIncomplete] = React.useState<FileList[]>([]);
    const [ticker, setTicker] = React.useState(0);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [fileLink, setFileLink] = React.useState('');

    const setFile = (a: any) => {
        setTicker(ticker + 1);
        setFileIncomplete(a);
    }

    useEffect(() => {
        setDisabled(!isFileListValid(file));
    }, [ticker])

    // SUBMIT FILES
    const { refetch: file_fetch, remove: file_remove, status: file_status, data: file_data } = useQuery('fileupload_edit', async () => {
        if(!file) throw new Error('Select a file first');
        const fileLists = file as FileList[];
        let zip = new JSZip();
        for(let i = 0; i < fileLists.length; i++) {
            const file_to_add = fileLists[i][0];
            zip.file(file_to_add.name, await file_to_add.arrayBuffer());
        }
        const formData = new FormData();
        const zipFileBufferArray = await zip.generateAsync({type: 'arraybuffer'});
        formData.append('files', new Blob([zipFileBufferArray], {type: 'zip'}), 'formfiles.zip');
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/fileupload`, {
            method: 'POST',
            headers: {
                usertoken: sessionDetails.access_token,
            },
            body: formData
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
        onSuccess: (data) => {
            const link = data.DATA.file_url as string;
            setFileLink(link);
        },
        onError: (data) => {
            setFileLink("User's file upload failed.");
        }
    });
    const loading = file_status !== 'success' || file_data.STATUS !== 'SUCCESS';

    // Add to Time Entry
    const {
        refetch: add_fetch,
        remove: add_remove,
        status: add_status,
        data: add_data,
    } = useQuery('timeentry_update_files_link', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entry_filelink`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    id: props.data.id,
                    file_link: props.data.file_link + ',' + fileLink
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false, 
    });
    const add_loading = add_status !== 'success' || add_data.STATUS !== 'SUCCESS';

    useEffect(() => {
        if(fileLink.length > 0) {
            add_fetch();
        }
    }, [fileLink]);

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: 'Upload File',
            attributeType: MenuAttributeTypes.file_upload,
            set_value: file,
            setValue: setFile,
            multi: true
        }
    ];

    const onSubmit = () => {
        file_fetch();
    }

    useEffect(() => {
        if (!add_loading) {
            file_remove();
            add_remove();
            props.closeModal();
            props.refreshTable();
        }
    }, [add_loading, props, file_remove]);
    
    return (
        <FormModal 
            title='Add More Files To This Time Entry'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={() => disabled}
            onSave={onSubmit}
            message='These files will be added in addition to currently uploaded files.'
        />
    )
};

export default TimeEntryUploadFilesModal;