import React, { useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import ClientTable from './ClientTable';

const queryClient = new QueryClient()

interface ClientPageProps {
    redirectTo: (location: string) => void;
}

const ClientPage = (props: ClientPageProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <ClientPageInner {...props}/>
    </QueryClientProvider>
);

const ClientPageInner = (props: ClientPageProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const { data, status, refetch } = useQuery('client_table', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/clients_with_contacts?access_token=${sessionDetails.access_token}`, {
            method: 'GET'
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false // so it won't refetch on page click
    });
    const loading = status !== 'success' || data.STATUS !== 'SUCCESS';
    
    return (
        <div>
            <ClientTable
                loading={loading}
                data={loading ? [] : data.DATA}
                refresh={refetch}
                redirectTo={props.redirectTo}
            />
        </div>
    )
};

export default ClientPage;