import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import FormModal from '../../modal/FormModal';
import { MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';


const queryClient = new QueryClient();

export interface LockDateModalProps {
    isOpen: boolean;
    closeModal: () => void;
    refreshDate: () => void;
}

const LockDateModal = (props: LockDateModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <LockDateModalInner {...props}/>
    </QueryClientProvider>
)
const LockDateModalInner = (props: LockDateModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);
    const [lockDate, setLockDate] = React.useState(new Date().toString());

    const isSaveButtonDisabled = () => false;

    const { refetch } = useQuery('lockDate_update', async () => {
        
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/lock_date`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: { lockDate }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: () => {
            props.refreshDate();
            props.closeModal();
        }
    });

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Lock Date",
            attributeType: MenuAttributeTypes.date_input,
            set_value: lockDate,
            setValue: setLockDate,
        }
    ];

    return (
        <FormModal 
            title='Update Lock Date'
            form_strategy='Update'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={refetch}
            message='Non-admin section of site will only be able to submit timesheets after (and not including) this date'
        />
    );
}

export default LockDateModal;