import FileSaver from 'file-saver';
import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserSessionDetails, UserSessionDetailsContext } from '../../App';
import { lists_to_CSV } from '../../common/utils';
import { Personnel } from '../../content/personnel/admin/PersonnelTableAdmin';
import { getCSVForTimeEntries } from '../../content/time_entries/TimeEntryPageUser';
import { TimeEntry } from '../../content/time_entries/TimeEntryTable';
import { Workorder } from '../../content/workorders/WorkorderTable';
import FormModal from '../../modal/FormModal';
import { DropdownItem, MenuAttributeProps, MenuAttributeTypes } from '../../modal/MenuAttribute';

const queryClient = new QueryClient();

export interface ExportDataModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const ExportDataModal = (props: ExportDataModalProps): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <ExportDataModalInner {...props}/>
    </QueryClientProvider>
)
const ExportDataModalInner = (props: ExportDataModalProps): JSX.Element => {
    const sessionDetails: UserSessionDetails = useContext(UserSessionDetailsContext);

    const [startDate, setStartDate] = React.useState(new Date().toString());
    const [endDate, setEndDate] = React.useState(new Date().toString());
    const [personnel, setPersonnel] = React.useState({id: '', display: ''} as DropdownItem);
    const [personnelOptions, setPersonnelOptions] = React.useState([] as DropdownItem[]);
    const [workorder, setWorkorder] = React.useState({id: '', display: ''} as DropdownItem);
    const [workorderOptions, setWorkorderOptions] = React.useState([] as DropdownItem[]);


    const [buttonDisabled, setButtonDisabled] = React.useState(false);

    // WORKORDER FETCHING
    const { 
        data: get_workorder_data,
        status: get_workorder_status, 
    } = useQuery('get_workorders', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/workorders?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const options = data.DATA.map((workorder: Workorder) => ({
                id: workorder.id + '',
                display: workorder.workorder_name
            } as DropdownItem));
            setWorkorderOptions(options);
        }
    });
    const get_workorder_loading = get_workorder_status !== 'success' || get_workorder_data.STATUS !== 'SUCCESS';
    

    // PERSONNEL FETCHING
    const { 
        data: get_personnel_data,
        status: get_personnel_status, 
        refetch: get_personnel_refetch
    } = useQuery('get_personnel_workorder', async () => {
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/personnel?access_token=${sessionDetails.access_token}`, {
            method: 'GET',
        });
        return res.json();
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const options: DropdownItem[] = data.DATA.personnel.map((employee: Personnel) => ({
                id: employee.id + '',
                display: employee.full_name
            } as DropdownItem));
            setPersonnelOptions(options);
        }
    });
    const get_personnel_loading = get_personnel_status !== 'success' || get_personnel_data.STATUS !== 'SUCCESS';
    

    const { refetch: downloadCSVFetch } = useQuery('csv_download_all', async () => {        
        setButtonDisabled(true);
        const res = await fetch(`${sessionDetails.backend.host}:${sessionDetails.backend.port}/time_entry_all_download?access_token=${sessionDetails.access_token}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: sessionDetails.access_token,
                req: {
                    startDate,
                    endDate,
                    workorder_id: workorder.id.length > 0 ? workorder.id : undefined,
                    personnel_id: personnel.id.length > 0 ? personnel.id : undefined
                }
            })
        });
        return res.json();
    }, { // options are here so it won't auto-fetch
        refetchOnWindowFocus: false,
        enabled: false,
        retry: false,
        onSuccess: (data) => {
            console.log('Saving file.' + data.STATUS);
            if (data.STATUS === 'SUCCESS') {
                console.log('Saving file.');
                const time_entries = data.DATA as TimeEntry[];
                const csv_content = getCSVForTimeEntries(time_entries, true);
                const csv_blob = new Blob([csv_content], { type: 'text/csv;charset=utf-8 ;'});
                FileSaver.saveAs(csv_blob, 'all_timesheet.csv');
                setButtonDisabled(false);
                props.closeModal();
            }
        },
        onError: (e) => {
            console.log(e);
            setButtonDisabled(false);
        },
    });

    const menuAttributes: MenuAttributeProps[] = [
        {
            name: "Start Date",
            attributeType: MenuAttributeTypes.date_input,
            set_value: startDate,
            setValue: setStartDate
        }, {
            name: "End Date",
            attributeType: MenuAttributeTypes.date_input,
            set_value: endDate,
            setValue: setEndDate
        }, {
            name: "Workorder",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: workorder,
            setValue: setWorkorder,
            dropdown_values: workorderOptions
        }, {
            name: "Personnel",
            attributeType: MenuAttributeTypes.dropdown_input,
            set_value: personnel,
            setValue: setPersonnel,
            dropdown_values: personnelOptions
        }
    ];

    const onSubmit = () => {
        downloadCSVFetch();
    }

    const isSaveButtonDisabled = () => buttonDisabled;

    return (
        <FormModal 
            title='Export Data as CSV'
            form_strategy='Fetch'
            menuAttributes={menuAttributes}
            onClose={props.closeModal}
            isOpen={props.isOpen}
            isSaveButtonDisabled={isSaveButtonDisabled}
            onSave={onSubmit}
        />
    );

}

export default ExportDataModal;